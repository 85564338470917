import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { Link, useNavigate } from "react-router-dom";
import { scrollIntoView } from "seamless-scroll-polyfill";
import MailIcon from '@mui/icons-material/Mail';


export default function Footer() {

    const navigate = useNavigate()

    const handleLinkDeveloper = () => {
        window.open('https://www.bastian-albaut.fr', '_blank')
    }

    const handleScrollTop = () => {
        const body = document.querySelector('#root');
        body.scrollIntoView({behavior: 'smooth'})
    }

    const handleLogin = () => {
        navigate("/login")
    }

    return(
        // Section : Footer
        <Box id='boxFooter'>
            <Box id='containerFooter1'>
                <Box id="linkMailFooter" onClick={() => window.location = 'mailto:appartement.alize@gmail.com'}>
                    <MailIcon id="iconMailFooter"/>
                    <Typography id="typoMailFooter" variant="body1" color="initial">appartement.alize@gmail.com</Typography>
                </Box>
            </Box>
            <Box id='containerFooter2'>
                <Typography className="typoFooter leftFooter linkFooter" variant="body2" color="initial" onClick={handleLogin}>Tableau de bord</Typography>
                <Typography className="typoFooter centerFooter" variant="body2" color="text.secondary">Copyright © <span className='linkFooter' onClick={handleScrollTop}>Appartement Alizé</span> {new Date().getFullYear()}</Typography>
                <Typography className="typoFooter rightFooter" variant="body2" color="initial">Site réalisé par <span className='linkFooter' onClick={handleLinkDeveloper}>Bastian Albaut</span></Typography>
            </Box>
        </Box>
    );
}