import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Alert, TextField, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { sendMail } from '../api/index.js';
import { validateName, validateFirstname, validatePhone, validateMail } from './formValidation.js';

export default function Result(props) {

    const theme = useTheme()

    const [displayDetail, setDisplayDetail] = useState(false);
    const [detailPriceByNight, setDetailPriceByNight] = useState({})

   // Component did mount 
    useEffect(() => {
        const nightsBySeasons = getNightsBySeasons()
        setDetailPriceByNight(nightsBySeasons)
    }, [props])

    const handleDisplayDetail = () => {
        if(displayDetail) {
            setDisplayDetail(false);
        } else {
            setDisplayDetail(true);
        }
    }

    const getNightsBySeasons = () => {
        let nightsBySeasons = []
        nightsBySeasons.push({ basseSaison : props.allDatesWithPrice.filter((day) => day.libelleSeason === "Basse saison")})
        nightsBySeasons.push({ moyenneSaison : props.allDatesWithPrice.filter((day) => day.libelleSeason === "Moyenne saison")})
        nightsBySeasons.push({ hauteSaison : props.allDatesWithPrice.filter((day) => day.libelleSeason === "Haute saison")})
        nightsBySeasons.push({ tresHauteSaison : props.allDatesWithPrice.filter((day) => day.libelleSeason === "Très Haute saison")})
        return nightsBySeasons
    }

    // handle form
    const [dataForm, setDataForm] = useState({name:'', firstname:'', phone: '', mail: ''})
    const [nameError, setNameError] = useState('');
    const [firstnameError, setFirstnameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [mailError, setMailError] = useState('');
    const [globalError, setGlobalError] = useState('');
    const [globalSuccess, setGlobalSuccess] = useState('');

    const handleShowError = (msg) => {
        setGlobalSuccess('')
        setGlobalError(msg)
        setTimeout(() => {
            setGlobalError('');
         }, 4000)
    }

    const handleShowSuccess = (msg) => {
        setGlobalError('')
        setGlobalSuccess(msg)
        setTimeout(() => {
            setGlobalSuccess('');
         }, 4000)
    }

    // Check validation form
    useEffect(() => {
        validateName({name: dataForm.name, setNameError})
        validateFirstname({firstname: dataForm.firstname, setFirstnameError})
        validatePhone({phone: dataForm.phone, setPhoneError})
        validateMail({mail: dataForm.mail, setMailError})
    }, [dataForm])

    const handleValidation = () => {
        if(nameError === '' && firstnameError === '' && phoneError === '' && mailError === '' && dataForm.name !== '' && dataForm.firstname !== '' && dataForm.phone !== '' && dataForm.mail !== '') {
            return true;
        } else {
            return false;
        }
    }

    const handleSubmit = async () => {
        if(handleValidation()) {
            try {
                const reservationData = {
                    ...dataForm, 
                    start_date: props.data.startDate.toLocaleString('fr-FR', { month: 'long', day: 'numeric', year: "numeric"}), 
                    end_date: props.data.endDate.toLocaleString('fr-FR', { month: 'long', day: 'numeric', year: "numeric"}), 
                    price: (props.finalPrice + 80 + (props.data.countAdult*props.data.dayDifference*1.8)).toFixed(2), 
                    adult: props.data.countAdult, 
                    children: props.data.countChildren
                }
                const res = await sendMail(reservationData);
                if(res && res.data) {
                    setDataForm({...dataForm, name:'', firstname:'', phone: '', mail: ''})
                    handleShowSuccess("Le formulaire a été envoyé avec succès !")
                }
            } catch(error) {
                console.log(error)
                handleShowError("Une erreur s'est produite durant l'envoi du formulaire.")
            }
        } else {
            handleShowError("Certains champs du formulaire n'ont pas été correctement rempli.")
        }
    }

    return(
        // Section 6: Résultat de réservation
        <Box ref={props.refResult} id="sectionResult" className="section" sx={{ backgroundColor: "#8DAA91" }}>
            
            {globalError ? (
            <Box className="boxAlert">
                <Alert className="alert" severity="error" onClose={() => {setGlobalError('')}}>{globalError}</Alert>
            </Box>
            ) : (
            null
            )}
            {globalSuccess ? (
            <Box className="boxAlert">
                <Alert className="alert" severity="success" onClose={() => {setGlobalSuccess('')}}>{globalSuccess}</Alert>
            </Box>
            ) : (
            null
            )}
            <Typography id='typoTitleReservation' variant="h3" color="initial">Résultat:</Typography>
            <Box id='boxDatePrixResult'>
                <Box id='boxDateResult'>
                    <Typography className='typoDateResult' variant="h3" color="initial">Du {<span className='boldDate'>{props.data.startDate.toLocaleString('fr-FR', { month: 'long', day: 'numeric', year: "numeric"})}</span>}</Typography>
                    <Typography className='typoDateResult' variant="h3" color="initial">Au {<span className='boldDate'>{props.data.endDate.toLocaleString('fr-FR', { month: 'long', day: 'numeric', year: "numeric" })}</span>}</Typography>
                </Box>
                <Box id='boxPrixResult'>
                    <Typography variant="h3" color="initial">{((props.finalPrice + 80 + (props.data.countAdult*props.data.dayDifference*1.8)).toFixed(2)).replace('.', ',')}€</Typography>
                    {!displayDetail && (
                        <Button className="buttonDetailPrix" variant="outlined" size='small' color="black" onClick={handleDisplayDetail}>Détails</Button>
                    )}
                </Box>
            </Box>
            {displayDetail && (
                <Box id="boxDetailPrix">
                    <CloseIcon className='iconCloseDetailResult' onClick={handleDisplayDetail}/>
                    <Typography className='typoTitleDetail' variant="h4" color="initial">Détails:</Typography>
                    {detailPriceByNight[0].basseSaison.length !== 0 && (
                        <Box className="boxDetailPrixElement">
                            <Typography variant="body1" color="initial">* Prix par nuit <span className='noWrap'>"Basse saison"</span></Typography>
                            <Typography className='typoPriceDetailElement' variant="body1" color="initial">{detailPriceByNight[0].basseSaison.length} x {props.seasonsData[0].price}€</Typography>
                        </Box>
                    )}
                    {detailPriceByNight[1].moyenneSaison.length !== 0 && (
                        <Box className="boxDetailPrixElement">
                            <Typography variant="body1" color="initial">* Prix par nuit <span className='noWrap'>"Moyenne saison"</span></Typography>
                            <Typography className='typoPriceDetailElement' variant="body1" color="initial">{detailPriceByNight[1].moyenneSaison.length} x {props.seasonsData[1].price}€</Typography>
                        </Box>
                    )}
                    {detailPriceByNight[2].hauteSaison.length !== 0 && (
                        <Box className="boxDetailPrixElement">
                            <Typography variant="body1" color="initial">* Prix par nuit <span className='noWrap'>"Haute saison"</span></Typography>
                            <Typography className='typoPriceDetailElement' variant="body1" color="initial">{detailPriceByNight[2].hauteSaison.length} x {props.seasonsData[2].price}€</Typography>
                        </Box>
                    )}
                    {detailPriceByNight[3].tresHauteSaison.length !== 0 && (
                        <Box className="boxDetailPrixElement">
                            <Typography variant="body1" color="initial">* Prix par nuit <span className='noWrap'>"Très Haute saison"</span></Typography>
                            <Typography className='typoPriceDetailElement' variant="body1" color="initial">{detailPriceByNight[3].tresHauteSaison.length} x {props.seasonsData[3].price}€</Typography>
                        </Box>
                    )}
                    <Box className="boxDetailPrixElement">
                        <Typography variant="body1" color="initial">* Frais de ménage</Typography>
                        <Typography variant="body1" color="initial">80€</Typography>
                    </Box>
                    <Box className="boxDetailPrixElement">
                        <Typography variant="body1" color="initial">* Taxe {props.data.countAdult} adulte{props.data.countAdult > 1 ? ("s") : null}</Typography>
                        <Typography variant="body1" color="initial">{((props.data.countAdult*props.data.dayDifference*1.8).toFixed(2)).replace('.', ',')}€</Typography>
                    </Box>
                </Box>
            )}
            <Typography className='typoForm' variant="h3" color="initial">Réserver l'appartement</Typography>
            <Typography className='typoForm2' variant="body1" color="initial">(Retour par mail de l'hôte très rapide)</Typography>
            <Box className='boxForm'>
                {nameError ? (
                    <TextField error id="standard-error" helperText={nameError} InputLabelProps={{shrink: true}} className='inputForm' label="Nom" variant="standard" margin="dense" value={dataForm.name} onChange={(e) => setDataForm({...dataForm, name: e.target.value})} required/>
                ) : (
                    <TextField className='inputForm' label="Nom" variant="standard" margin="dense" value={dataForm.name} onChange={(e) => setDataForm({...dataForm, name: e.target.value})} required/>
                )}
                {firstnameError ? (
                    <TextField error id="standard-error" helperText={firstnameError} InputLabelProps={{shrink: true}} className='inputForm' label="Prénom" variant="standard" margin="dense" value={dataForm.firstname} onChange={(e) => setDataForm({...dataForm, firstname: e.target.value})} required/>
                ) : (
                    <TextField className='inputForm' label="Prénom" variant="standard" margin="dense" value={dataForm.firstname} onChange={(e) => setDataForm({...dataForm, firstname: e.target.value})} required/>
                )}
                {phoneError ? (
                    <TextField error id="standard-error" helperText={phoneError} InputLabelProps={{shrink: true}} className='inputForm' label="Numéro de téléphone" variant="standard" margin="dense" value={dataForm.phone} onChange={(e) => setDataForm({...dataForm, phone: e.target.value})} required/>
                ) : (
                    <TextField className='inputForm' label="Numéro de téléphone" variant="standard" margin="dense" value={dataForm.phone} onChange={(e) => setDataForm({...dataForm, phone: e.target.value})} required/>
                )}
                {mailError ? (
                    <TextField error id="standard-error" helperText={mailError} InputLabelProps={{shrink: true}} className='inputForm' label="Adresse Mail" variant="standard" margin="dense" value={dataForm.mail} onChange={(e) => setDataForm({...dataForm, mail: e.target.value})} required/>
                    ) : (
                    <TextField className='inputForm' label="Adresse Mail" variant="standard" margin="dense" value={dataForm.mail} onChange={(e) => setDataForm({...dataForm, mail: e.target.value})} required/>
                )}
            </Box>
            <Box id='boxConditionReservation'>
                <Typography variant="body1" color="initial">* Logement non-fumeur</Typography>
                <Typography variant="body1" color="initial">* Fêtes et soirées non acceptées</Typography>
                <Typography variant="body1" color="initial">* Animaux non acceptées</Typography>
            </Box>
            <Box className='boxButtonreservation'>
                <Button id='buttonReservation' className='buttonBold' size="large" variant="contained" color="primary" onClick={handleSubmit}>
                    Réserver
                </Button>
            </Box>
        </Box>
    );
}