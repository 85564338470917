import React, { useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform, Variants } from "framer-motion"

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { useMediaQuery, useTheme, Button } from '@mui/material';

import planeImage from "../Images/Location/avion.webp"
import troisIletsImage from "../Images/Location/AnseALane.webp"
import mapMartiniqueImage from "../Images/Location/mapMartinique.webp"
import ModalImage from './ModalImage';

export default function Location(props) {

    const theme = useTheme();

    // height plane in fly and map/plane width

    // less than 480px wide
    let planeY = -40;
    let planeWidth = 75;
    let mapWidth = 100;
    let appartementX = -70;
    let appartementY = -50;

    // Between 480px and 600px wide
    if(useMediaQuery(theme.breakpoints.between('xs','sm'))) {
        planeWidth = 85;
        mapWidth = 125;
        appartementX = -90;
        appartementY = -60;
    }

    // Between 600px et 768px wide
    if(useMediaQuery(theme.breakpoints.between('sm','md'))) {
        planeY = -60;
        planeWidth = 100;
        mapWidth = 150;
        appartementX = -95;
        appartementY = -70;
    }

    // Between 768px et 992px wide
    if(useMediaQuery(theme.breakpoints.between('md','lg'))) {
        planeY = -70;
        planeWidth = 125;
        mapWidth = 175;
        appartementX = -95;
        appartementY = -80;
    }

    // Between 992px et 1200px wide
    if(useMediaQuery(theme.breakpoints.between('lg','xl'))) {
        planeY = -80;
        planeWidth = 135;
        mapWidth = 200;
        appartementX = -85;
        appartementY = -90;
    }

    // Up 1200px wide
    if(useMediaQuery(theme.breakpoints.up('xl'))) {
        planeY = -90;
        planeWidth = 150;
        mapWidth = 225;
        appartementX = -80;
        appartementY = -95;
    }

    // Plane Animation
    const refPlane = useRef(null);
    const { scrollYProgress } = useScroll({
        target: refPlane,
    });

    const [windowSize, setWindowSize] = useState(null)

    const handleResize = () => {
        setWindowSize(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    
    let scrollTransformX = useTransform(scrollYProgress, [0, 1], [window.innerWidth-planeWidth-mapWidth, 0-planeWidth]);
    let scrollTransformY = useTransform(scrollYProgress, [0, 0.5, 1], [0, planeY, 0]);
    let scrollRotateX = useTransform(scrollYProgress, [0, 0.5, 1], [25, 0, -25]);
    
    // Modal plan
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    // Effect scroll reveal
    const variantsMotion: Variants = {
        offscreen: {
            opacity: 0
          },
        onscreen: {
            opacity: 1,
            transition: {
                delay: 0.4,
                duration: 0.6
            }
        },
    };

    const variantsMotionBoxImg: Variants = {
        offscreen: {
            y: 200
          },
        onscreen: {
            y: 0,
            transition: {
                delay: 0.2,
                duration: 0.8
            }
        },
    };

    return(
        // Section 2: Location
        <Box ref={props.refDiscover} id="sectionLocation" className="section" sx={{ backgroundColor: "#fff" }}>
            <Typography className="title" variant="h2" color="#3A4F41">Emplacement de l'appartement</Typography>
            <Box className="boxPlane">
                <motion.div
                    style={{
                        x: scrollTransformX,
                        y: scrollTransformY,
                        rotate: scrollRotateX
                    }}
                >
                    <img className="plane" src={planeImage} alt="Avion qui se déplace vers la martinique"></img>
                </motion.div>
                <Box className="boxMapMartinique">
                    <Typography ref={refPlane} className="typoMartinique" variant="body1" color="#3A4F41">Martinique</Typography>
                    <img className="mapMartinique" src={mapMartiniqueImage} alt="carte de la martinique"></img>
                    <motion.div
                    animate={{
                        x: appartementX,
                        y: appartementY
                    }}>
                        <Typography className="typoAppartement" variant="body1" color="#FBAF00">Appartement Alizé</Typography>
                    </motion.div>
                </Box>
            </Box>
            <Box className="containerTroisIlets">
                <Box className="boxTroisIlets">
                    <motion.div
                        variants={variantsMotionBoxImg}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true}}
                    >
                        <img className="troisIlets" src={troisIletsImage} alt="Photo de la plage à 300m de l'appartement"></img>
                    </motion.div>
                    <Typography className="body1TroisIlets" variant="body1" color="initial">
                        L'appartement Alizé est disponible à la location sur l'île de la Martinique, dans la charmante ville les Trois-îlets. Ce havre de paix se situe à l'Anse à l'Âne à 300m de l'une des plus belle plage de l'île.
                    </Typography>
                    <motion.div
                        variants={variantsMotion}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true}}
                    >
                        <Button className='buttonModalPlan buttonBold' onClick={handleOpenModal} variant="contained" color="primary">
                        Voir plan
                        </Button>
                    </motion.div>
                </Box>
            </Box>
            {openModal ? <ModalImage open={openModal} handleClose={handleCloseModal} /> : null}
        </Box>
    );
}