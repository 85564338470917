import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { scrollIntoView } from "seamless-scroll-polyfill";

import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, TextField, Typography, Button, IconButton, MenuItem, Menu, Select, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";

import { deleteReservation, postReservation, getReservations, putHoliday, getHolidays, getSeasons, putSeason } from '../api/index.js';

// Styles import
import "../Styles/admin.css"

export default function Adminpage(props) {

    const [seasonsData, setSeasonsData] = useState([])
    const [holidaysData, setHolidaysData] = useState([])
    const [reservationsData, setReservationsData] = useState([])
    const [userLogIn, setUserLoginIn] = useState(false)

    // componentDidMount
    useEffect(() => {
        // Check if the user is login on mount
        setUserLoginIn(props.getToken());
    },[])

    const fetchSeasons = async () => {
        try {
            const seasons = await getSeasons();
            if(seasons && seasons.data) {
                setSeasonsData(seasons.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchHolidays = async () => {
        try {
            const holidays = await getHolidays();
            if(holidays && holidays.data) {
                setHolidaysData(holidays.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchReservations = async () => {
        try {
            let reservations = await getReservations();
            if(reservations && reservations.data) {
                reservations.data.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
                setReservationsData(reservations.data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const useIsMount = () => {
        const isMountRef = useRef(true);
        useEffect(() => {
            isMountRef.current = false;
        }, []);
        return isMountRef.current;
    };

    const isMont = useIsMount();
    
    // After check that the user is login
    useEffect(() => {
        // Check if we are not in first render and the user is login
        if (!isMont && userLogIn) {
            fetchSeasons();
            fetchHolidays();
            fetchReservations();
        }
    },[userLogIn])
    
    const getHolidayById = (id) => {
        if(holidaysData.length !== 0) {
            return holidaysData.find((holiday) => holiday._id === id)
        } else {
            return ""
        }
    }

    // Handle Click on holidays in box modification
    const refHolidays = useRef(null);
    const scrollHolidays = () => scrollIntoView(refHolidays.current ,{behavior : "smooth"});

    // Data for modification form of season
    const [seasonData, setSeasonData] = useState({ libelle: "", price: 0, dates: [], holiday: "" });

    // Handle click on modification button
    const handleOpenModification = (season) => {
        setWantOpen(true);
        setBoxModification(season.libelle.replace(/\s/g, ''));
        if(season.dates.length === 0) {
            if(season.holiday !== undefined) {
                setSeasonData({libelle: season.libelle, price: season.price, holiday: season.holiday});
            }
        } else {
            if(season.dates.length === 1) {
                if(season.holiday !== undefined) {
                    setSeasonData({libelle: season.libelle, price: season.price, dates: [{ dayStart: season.dates[0].dayStart, dayEnd: season.dates[0].dayEnd, monthStart: season.dates[0].monthStart ,monthEnd: season.dates[0].monthEnd }], holiday: season.holiday});
                }
            } else {
                if(season.dates.length === 2) {
                    setSeasonData({libelle: season.libelle, price: season.price, dates: [{ dayStart: season.dates[0].dayStart, dayEnd: season.dates[0].dayEnd, monthStart: season.dates[0].monthStart ,monthEnd: season.dates[0].monthEnd }, { dayStart: season.dates[1].dayStart, dayEnd: season.dates[1].dayEnd, monthStart: season.dates[1].monthStart ,monthEnd: season.dates[1].monthEnd }], holiday: season.holiday});
                }
            }
        }
    }

    // Handle click on close modification button
    const handleCloseModification = (season) => {
        setWantOpen(false);
        setBoxModification(season.libelle.replace(/\s/g, ''));
    }

    // Display Box Modification
    const [boxModification, setBoxModification] = useState('');
    const [wantOpen, setWantOpen] = useState(false);
    const [currentBoxDisplay, setCurrentBoxDisplay] = useState('');


    useEffect(() => {
        // Check if we are not in first render
        if (!isMont) {
            // Check if we want to close or open box modification
            if(wantOpen === false) {
                let box = document.querySelector(`.${boxModification}`);
                box.removeAttribute('id');
                setCurrentBoxDisplay('');
            } else {
                let box = document.querySelector(`.${boxModification}`);
                box.setAttribute('id','boxModificationDisplay');
                if(currentBoxDisplay !== '') {
                    currentBoxDisplay.removeAttribute('id');
                }
                setCurrentBoxDisplay(box)
            }
        }
    }, [wantOpen, boxModification])
    
    // Update date in state season
    const handleChangeVariableDate = (variable, value, index) => {
        let datesForSeason = seasonData.dates.slice()
        if(variable === "dayStart" || variable === "dayEnd") {
            datesForSeason[index] = {...datesForSeason[index] , [variable]: parseInt(value)}
        } else {
            datesForSeason[index] = {...datesForSeason[index] , [variable]: value}
        }
        setSeasonData({...seasonData, dates: datesForSeason})
    }

    // Calculation of price
    const indexMonth = {
        0: "janvier",
        1: "février",
        2: "mars",
        3: "avril",
        4: "mai",
        5: "juin",
        6: "juillet",
        7: "août",
        8: "septembre",
        9: "octobre",
        10: "novembre",
        11: "décembre",
    }

    function getKeyByValue(object, value) {
        return parseInt(Object.keys(object).find(key => object[key] === value.toLowerCase()));
    }

    const checkStartDateUpEndDate = () => {
        let startDateUpEndDate = false
        seasonData.dates.map((date) => {
            const dateDebut = new Date(new Date().getFullYear(), getKeyByValue(indexMonth, date.monthStart), date.dayStart)
            const dateEnd = new Date(new Date().getFullYear(), getKeyByValue(indexMonth, date.monthEnd), date.dayEnd)
            if(dateDebut > dateEnd) {
                startDateUpEndDate = true
            }
        })

        return startDateUpEndDate
    }

    const checkLastDayMonth = () => {
        let arrayMonth = ["février", "avril", "juin", "septembre", "novembre"]
        seasonData.dates.map((date) => {
            if(date.dayStart === 31) {
                if(arrayMonth.includes(date.monthStart)) {
                    date.dayStart = 30;
                }
            }
            if(date.dayEnd === 31) {
                if(arrayMonth.includes(date.monthEnd)) {
                    date.dayEnd = 30;
                }
            }
        })
    }

    const handleValidationModification = async (seasonId) => {
        checkLastDayMonth()
        if(!checkStartDateUpEndDate()) {
            try {
                const res = await putSeason(seasonId, seasonData);
                if(res && res.data) {
                    setSeasonsData(seasonsData.map(season => season._id === seasonId ? { ...res.data } : season))
                    handleCloseModification(res.data)
                    window.scrollTo(0, 0)
                    handleShowSuccess("La saison a été modifié avec succès !")
                }
            } catch (error) {
                console.log(error)
                handleShowError('Erreur lors de la modification.')
                if(error.response.status === 401) {
                    handleLogout();
                }
            }
        } else {
            handleShowError("Erreur: La date de début doit être avant la date de fin")
        }
    }  

    // Handle click on button logout
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleBackSite = useCallback(() => navigate('/', {replace: true}), [navigate]);

    const handleLinkAnalytics = () => {
        window.open('https://analytics.google.com/analytics/web/#/p342175862/reports/intelligenthome', '_blank')
    }

    const handleLogout = () => {
        localStorage.clear()
        handleBackSite()
    }

    // Form to create an element
    const [formReservation, setFormReservation] = useState(false)
    const [reservationFormData, setReservationFormData] = useState({ libelle: "", startDate: null, endDate: null });
    const [formHoliday, setFormHoliday] = useState(false)
    const [holidayFormData, setHolidayFormData] = useState({ idHoliday: "", year: new Date().getFullYear(), dayStart: 0, dayEnd: 0, monthStart: 0, monthEnd: 0 });
    const [globalError, setGlobalError] = useState('');
    const [globalSuccess, setGlobalSuccess] = useState('');

    const handleShowError = (msg) => {
        setGlobalSuccess('')
        setGlobalError(msg)
        setTimeout(() => {
            setGlobalError('');
         }, 4000)
    }

    const handleShowSuccess = (msg) => {
        setGlobalError('')
        setGlobalSuccess(msg)
        setTimeout(() => {
            setGlobalSuccess('');
         }, 4000)
    }

    const { beforeToday } = DateRangePicker;

    const handleFormAdd = (type) => {
        if (type === "reservation") {
            setFormReservation(true)
        }
        if(type === "holiday") {
            setHolidayFormData({...holidayFormData, idHoliday: holidaysData[0]._id})
            setFormHoliday(true)
        }
    }

    const handleSubmitFormReservation = async () => {
        if(reservationFormData.libelle !== "" && reservationFormData.startDate !== null && reservationFormData.endDate !== null) {
            try {
                const res = await postReservation(reservationFormData);
                if(res && res.status === 201) {
                    fetchReservations()
                    setReservationFormData({libelle: "", startDate: new Date(), endDate: new Date()});
                    handleShowSuccess('Réservation ajoutée avec succès !')
                    setFormReservation(false)
                } else {
                    handleShowError('Erreur pendant la connexion avec le serveur.');
                }
            } catch(error) {
                console.log(error)
                handleShowError("Erreur pendant l'envoi du formulaire");
                if(error.response.status === 401) {
                    handleLogout();
                }
            }
        } else {
            handleShowError('Veuillez remplir tous les champs du formulaire');
        }
    }

    const handleDeleteReservation = async (id) => {
        try {
            const res = await deleteReservation(id);
            if(res && res.status === 200) {
                fetchReservations()
                handleShowSuccess('Réservation supprimée avec succès !')
            } else {
                handleShowError('Erreur lors de la suppression de la réservation.');
            }
        } catch(error) {
            console.log(error)
            handleShowError('Erreur lors de la suppression.');
            if(error.response.status === 401) {
                handleLogout();
            }
            handleShowError('Erreur lors de la suppression de la réservation.');
        }
    }

    const handleSubmitFormHoliday = async () => {
        if(holidayFormData.idHoliday !== "" && holidayFormData.dayStart !== 0 && holidayFormData.dayEnd !== 0 && holidayFormData.monthStart !== 0 && holidayFormData.monthEnd !== 0) {
            // Check if there is already holiday for this date
            let holidayAldreadyExist = false
            const holidayCorresponding = holidaysData.filter((holiday) => holiday._id === holidayFormData.idHoliday)
            // Avoid reference copying
            const holidayToModify = holidayCorresponding.slice()
            holidayAldreadyExist = holidayToModify[0].dates.some((date) => date.year === holidayFormData.year)
            
            if(!holidayAldreadyExist) {
                try {
                    const newDateHoliday = { year: holidayFormData.year, dayStart: holidayFormData.dayStart, monthStart: indexMonth[holidayFormData.monthStart-1], dayEnd: holidayFormData.dayEnd, monthEnd: indexMonth[holidayFormData.monthEnd-1] } 
                    holidayToModify[0].dates.push(newDateHoliday)
                    const res = await putHoliday(holidayFormData.idHoliday, holidayToModify[0]);
                    if(res && res.status === 200) {
                        fetchHolidays()
                        setHolidayFormData({ idHoliday: "", year: new Date().getFullYear(), dayStart: 0, dayEnd: 0, monthStart: 0, monthEnd: 0 })
                        handleShowSuccess('Vacances ajoutées avec succès !')
                        setFormHoliday(false)
                    } else {
                        handleShowError('Erreur pendant la connexion avec le serveur.');
                    }
                } catch(error) {
                    console.log(error)
                    handleShowError('Erreur lors de l\'envoi du formulaire.');
                    if(error.response.status === 401) {
                        handleLogout();
                    }
                }
            } else {
                handleShowError(`Il existe déjà des dates de vacances indiquées à l'année ${holidayFormData.year}.`);
            }
        } else {
            handleShowError('Veuillez remplir tous les champs du formulaire');
        }
    }

    // Delete holiday of year for holidayId corresponding
    const handleDeleteHoliday = async (holidayId, year) => {
        try {
            const holidayCorresponding = holidaysData.filter((holiday) => holiday._id === holidayId)
            const newDates = holidayCorresponding[0].dates.filter((date) => date.year !== year)
            holidayCorresponding[0].dates = newDates
            const res = await putHoliday(holidayId, holidayCorresponding[0]);
            if(res && res.status === 200) {
                fetchHolidays()
                handleShowSuccess('Vacances supprimée avec succès !')
            } else {
                handleShowError('Erreur lors de la suppression des vacances.');
            }
        } catch(error) {
            console.log(error)
            handleShowError('Erreur lors de la suppression des vacances.');
            if(error.response.status === 401) {
                handleLogout();
            }
            handleShowError('Erreur lors de la suppression des vacances.');
        }
    }


    // Message confirmation delete
    const [typeToDelete, setTypeToDelete] = useState("")
    const [dataDelete, setDataDelete] = useState({})

    const handleOpenConfirmationDeleteHoliday = (holidayId, year) => {
        setTypeToDelete("holiday")
        setDataDelete({holidayId: holidayId, year: year})
    };

    const handleOpenConfirmationDeleteReservation = (reservationId) => {
        setTypeToDelete("reservation")
        setDataDelete({reservationId: reservationId})
    };
    
    const handleCloseConfirmationDelete = () => {
        setTypeToDelete("");
    };

    if(!userLogIn) {
        navigate("/")
    } else {

        return(
            <>
                {globalError ? (
                <Box className="boxAlert">
                    <Alert className="alert" severity="error" onClose={() => {setGlobalError("")}}>{globalError}</Alert>
                </Box>
                ) : (
                null
                )}
                {globalSuccess ? (
                <Box className="boxAlert">
                    <Alert className="alert" severity="success" onClose={() => {setGlobalSuccess("")}}>{globalSuccess}</Alert>
                </Box>
                ) : (
                null
                )}

                {typeToDelete && (
                <Dialog open={typeToDelete !== ""} onClose={handleCloseConfirmationDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Suppression</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Êtes-vous sûr ?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirmationDelete}>Annuler</Button>
                        <Button onClick={() => { if(typeToDelete === "holiday") {handleDeleteHoliday(dataDelete["holidayId"], dataDelete["year"])} if(typeToDelete === "reservation") {handleDeleteReservation(dataDelete["reservationId"])} handleCloseConfirmationDelete() }} autoFocus>Supprimer</Button>
                    </DialogActions>
                </Dialog>
                )}

                <Box id='navBarAdmin'>
                    <Box>
                        <Typography id='titleAdminPanel' variant='h1' color="white">Tableau {<span className='noWrap'>de bord</span>}</Typography>
                        <Typography id='subtitleAdminpanel' variant="subtitle1" color="white">Appartement Alizé</Typography>
                    </Box>
                    <IconButton aria-label="Bouton de déconnexion" onClick={handleMenu}>
                        <LogoutIcon className='logoutIcon'/>
                    </IconButton>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{vertical: 'top', horizontal: 'right',}}
                        keepMounted
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleBackSite}>Retour au site</MenuItem>
                        <MenuItem onClick={handleLinkAnalytics}>Statistique de visite</MenuItem>
                        <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
                    </Menu>
                </Box>
                <Box id="containerAdmin">
                    <Box id="boxReservation">
                        <Typography className="typoH2AdminPanel" variant="h2" color="#FBAF00">Réservations</Typography>
                        
                        {formReservation ? (
                            <Box className='boxFormReservation'>
                                <CloseIcon className='iconAdminPrice iconCloseModification' onClick={() => { setReservationFormData({libelle: "", startDate: new Date(), endDate: new Date()}); setFormReservation(false); }}/>
                                <Typography className='typoTitleFormReservation' variant="h3" color="white">Création</Typography>
                                <TextField sx={{ input: { color: 'white' } }} className='inputLibelleReservation' id="outlined-number" label="Description" type="text" value={reservationFormData.libelle} /*Si update: InputLabelProps={{shrink: true}}*/ required onChange={(e) => setReservationFormData({...reservationFormData, libelle: e.target.value})} />
                                <DateRangePicker 
                                    className='inputDateReservation'
                                    onChange={(range) => { range !== null ? (setReservationFormData({...reservationFormData, startDate: range[0], endDate: range[1]}))
                                                                          : ( setReservationFormData({...reservationFormData, startDate: new Date(), endDate: new Date()}) )}} 
                                    showOneCalendar
                                    disabledDate={beforeToday()}
                                    format="dd/MM/yyyy" 
                                    locale={{
                                        february: 'Fev',
                                        sunday: 'Di',
                                        monday: 'Lu',
                                        tuesday: 'Ma',
                                        wednesday: 'Me',
                                        thursday: 'Je',
                                        friday: 'Ve',
                                        saturday: 'Sa',
                                        ok: 'OK',
                                        today: 'Aujourd\'hui',
                                        yesterday: 'Hier',
                                        hours: 'Heures',
                                        minutes: 'Minutes',
                                        seconds: 'Secondes',
                                        last7Days: '7 derniers jours'
                                    }}
                                    />
                                <Button variant="contained" color="primary" onClick={handleSubmitFormReservation}>
                                  Envoyer
                                </Button>
                            </Box>
                        ) : ( 
                            <Button className='buttonAddForm' variant="outlined" color="primary" onClick={(e) => handleFormAdd("reservation")}>
                            Ajouter
                            </Button>
                        )}
                        {reservationsData.length === 0 ? (
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress sx={{ margin: 'auto' }}/>
                            </Box>
                        ) : (
                            <List>
                                { reservationsData.map((reservation) => (
                                    <ListItem key={reservation._id} className="listItemReservation">
                                        <Box className="listItemReservationContent">
                                            <ListItemText primary={<Typography className='listItemTextBold' variant="body1" color="#FBAF00">{reservation.libelle}</Typography>} secondary={<Typography variant="body2" color="white">{"Du " + new Date(reservation.startDate).getDate() + "/" + eval(new Date(reservation.startDate).getMonth() + 1) + "/" + new Date(reservation.startDate).getFullYear() + " au " + new Date(reservation.endDate).getDate() + "/" + eval(new Date(reservation.endDate).getMonth() + 1) + "/" + new Date(reservation.endDate).getFullYear()}</Typography>}/>
                                            <ListItemIcon className="boxEditIconAdminPrice">
                                                <Delete className='iconAdminPrice iconDelete' onClick={() => handleOpenConfirmationDeleteReservation(reservation._id)}/>
                                            </ListItemIcon>                                
                                        </Box>
                                        <Divider className='dividerAdmin'/>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                    <Box id="boxPrice">
                        <Typography className="typoH2AdminPanel" variant="h2" color="#FBAF00">Saisons</Typography>
                        <Typography className="typoSubtitleAdminPanel" variant="body1" color="white">(Si 2 saisons se chevauchent: le prix le plus chère est retenu)</Typography>
                        {seasonsData.length === 0 ? (
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress sx={{ margin: 'auto' }}/>
                            </Box>
                        ) : (
                        <List>
                            { seasonsData.map((season) => (
                                <ListItem key={season._id} className="listItemPrice">
                                    <Box className="listItemPriceContent">
                                        {season.dates.length === 0 ? (
                                            season.holiday !== undefined && (
                                                <ListItemText primary={<Typography className='listItemTextBold' variant="body1" color="#FBAF00">{season.libelle}</Typography>} secondary={<Typography variant="body2" color="white">{`Vacances ${getHolidayById(season.holiday).libelle}`}</Typography>}/>
                                            )
                                        ) : (season.dates.length === 1) ? (
                                            (season.holiday !== undefined) && (
                                                <ListItemText primary={<Typography className='listItemTextBold' variant="body1" color="#FBAF00">{season.libelle}</Typography>} secondary={<Typography variant="body2" color="white">{`Du ${season.dates[0].dayStart} ${season.dates[0].monthStart} au ${season.dates[0].dayEnd} ${season.dates[0].monthEnd} + Vacances ${getHolidayById(season.holiday).libelle}`}</Typography>}/>
                                            )
                                        ) : (season.dates.length === 2) ? (
                                            <ListItemText primary={<Typography className='listItemTextBold' variant="body1" color="#FBAF00">{season.libelle}</Typography>} secondary={<Typography variant="body2" color="white">{`Du ${season.dates[0].dayStart} ${season.dates[0].monthStart} au ${season.dates[0].dayEnd} ${season.dates[0].monthEnd} + Du ${season.dates[1].dayStart} ${season.dates[1].monthStart} au ${season.dates[1].dayEnd} ${season.dates[1].monthEnd}`}</Typography>}/>
                                        ) : (
                                            null
                                        )}
                                        <ListItemText primary={<Typography className='listItemTextBold' variant="body1" color="white">{`${season.price} €`}</Typography>} className='seasonItemPrice'/>
                                        <ListItemIcon className="boxEditIconAdminPrice" onClick={() => handleOpenModification(season)}>
                                            <EditIcon className='iconAdminPrice'/>
                                        </ListItemIcon>                                
                                    </Box>
                                    <Box className={`boxModificationPrice ${season.libelle.replace(/\s/g, '')}`}>
                                        <CloseIcon className='iconAdminPrice iconCloseModification' onClick={() => handleCloseModification(season)}/>
                                        <Typography id='typoModificationAdmin' variant="h3" color="initial">Modification</Typography>
                                        <Box id='boxFormAdminPrice'>
                                            { season.dates.length === 0 ? (
                                                season.holiday !== undefined && (
                                                    <Box className='boxModificationHolidays1'>
                                                        <Typography className='typoDateStartEnd' variant="body1" color="initial">Date début:</Typography>
                                                        <Typography onClick={scrollHolidays} className="typoPickSeason" variant="body1" color="initial">Début vacances {getHolidayById(season.holiday).libelle}</Typography>
                                                        <Typography className='typoDateStartEnd' variant="body1" color="initial">Date Fin:</Typography>
                                                        <Typography onClick={scrollHolidays} className="typoPickSeason" variant="body1" color="initial">Fin vacances {getHolidayById(season.holiday).libelle}</Typography>
                                                    </Box>
                                                )
                                            ) : (season.dates.length === 1) ? (
                                                (season.holiday !== undefined) && (
                                                    <Box className='boxModificationDate'>
                                                        <Box className='boxModificationDateContent'>
                                                            <Typography className='typoDateStartEnd' variant="body1" color="initial">Date début:</Typography>
                                                            <Box className='boxModificationJourMois'>
                                                                <TextField className='inputFormModificationPrice textFieldFormModificationPrice' id="outlined-number" label="Jour" type="number" InputLabelProps={{shrink: true}} defaultValue={season.dates[0].dayStart} onChange={(e) => handleChangeVariableDate("dayStart", e.target.value, 0)} InputProps={{ inputProps: { max: 31 } }}/>
                                                                <Select className='inputFormModificationPrice'  defaultValue={season.dates[0].monthStart} label="Mois *" onChange={(e) => handleChangeVariableDate("monthStart", e.target.value, 0)}>
                                                                    <MenuItem value={"janvier"}>Janvier</MenuItem>
                                                                    <MenuItem value={"février"}>Février</MenuItem>
                                                                    <MenuItem value={"mars"}>Mars</MenuItem>
                                                                    <MenuItem value={"avril"}>Avril</MenuItem>
                                                                    <MenuItem value={"mai"}>Mai</MenuItem>
                                                                    <MenuItem value={"juin"}>Juin</MenuItem>
                                                                    <MenuItem value={"juillet"}>Juillet</MenuItem>
                                                                    <MenuItem value={"août"}>Août</MenuItem>
                                                                    <MenuItem value={"septembre"}>Septembre</MenuItem>
                                                                    <MenuItem value={"octobre"}>Octobre</MenuItem>
                                                                    <MenuItem value={"novembre"}>Novembre</MenuItem>
                                                                    <MenuItem value={"décembre"}>Décembre</MenuItem>
                                                                </Select>
                                                            </Box>
                                                        </Box>
                                                        <Box className='boxModificationDateContent'>
                                                            <Typography className='typoDateStartEnd' variant="body1" color="initial">Date Fin:</Typography>
                                                            <Box className='boxModificationJourMois'>
                                                                <TextField className='inputFormModificationPrice textFieldFormModificationPrice' id="outlined-number" label="Jour" type="number" InputLabelProps={{shrink: true}} defaultValue={season.dates[0].dayEnd} onChange={(e) => handleChangeVariableDate("dayEnd", e.target.value, 0)} InputProps={{ inputProps: { max: 31 } }}/>
                                                                <Select className='inputFormModificationPrice'  defaultValue={season.dates[0].monthEnd} label="Mois *" onChange={(e) => handleChangeVariableDate("monthEnd", e.target.value, 0)}>
                                                                    <MenuItem value={"janvier"}>Janvier</MenuItem>
                                                                    <MenuItem value={"février"}>Février</MenuItem>
                                                                    <MenuItem value={"mars"}>Mars</MenuItem>
                                                                    <MenuItem value={"avril"}>Avril</MenuItem>
                                                                    <MenuItem value={"mai"}>Mai</MenuItem>
                                                                    <MenuItem value={"juin"}>Juin</MenuItem>
                                                                    <MenuItem value={"juillet"}>Juillet</MenuItem>
                                                                    <MenuItem value={"août"}>Août</MenuItem>
                                                                    <MenuItem value={"septembre"}>Septembre</MenuItem>
                                                                    <MenuItem value={"octobre"}>Octobre</MenuItem>
                                                                    <MenuItem value={"novembre"}>Novembre</MenuItem>
                                                                    <MenuItem value={"décembre"}>Décembre</MenuItem>
                                                                </Select>
                                                            </Box>
                                                        </Box>
                                                        <Box id='boxModificationHolidays2'>
                                                            <Typography className='typoDateStartEnd' variant="body1" color="initial">Date début:</Typography>
                                                            <Typography onClick={scrollHolidays} className="typoPickSeason" variant="body1" color="initial">Début vacances {getHolidayById(season.holiday).libelle}</Typography>
                                                            <Typography className='typoDateStartEnd' variant="body1" color="initial">Date Fin:</Typography>
                                                            <Typography onClick={scrollHolidays} className="typoPickSeason" variant="body1" color="initial">Fin vacances {getHolidayById(season.holiday).libelle}</Typography>                                                        
                                                        </Box>
                                                    </Box>
                                                )
                                            ) : (season.dates.length === 2) && (
                                                    <Box className='boxModificationDate' key={season.dates[0].monthStart}>
                                                        <Box className='boxModificationDateContent'>
                                                            <Typography className='typoDateStartEnd' variant="body1" color="initial">Date début:</Typography>
                                                            <Box className='boxModificationJourMois'>
                                                                <TextField className='inputFormModificationPrice textFieldFormModificationPrice' id="outlined-number" label="Jour" type="number" InputLabelProps={{shrink: true}} defaultValue={season.dates[0].dayStart} onChange={(e) => handleChangeVariableDate("dayStart", e.target.value, 0)} InputProps={{ inputProps: { max: 31 } }}/>
                                                                <Select className='inputFormModificationPrice'  defaultValue={season.dates[0].monthStart} label="Mois *" onChange={(e) => handleChangeVariableDate("monthStart", e.target.value, 0)}>
                                                                    <MenuItem value={"janvier"}>Janvier</MenuItem>
                                                                    <MenuItem value={"février"}>Février</MenuItem>
                                                                    <MenuItem value={"mars"}>Mars</MenuItem>
                                                                    <MenuItem value={"avril"}>Avril</MenuItem>
                                                                    <MenuItem value={"mai"}>Mai</MenuItem>
                                                                    <MenuItem value={"juin"}>Juin</MenuItem>
                                                                    <MenuItem value={"juillet"}>Juillet</MenuItem>
                                                                    <MenuItem value={"août"}>Août</MenuItem>
                                                                    <MenuItem value={"septembre"}>Septembre</MenuItem>
                                                                    <MenuItem value={"octobre"}>Octobre</MenuItem>
                                                                    <MenuItem value={"novembre"}>Novembre</MenuItem>
                                                                    <MenuItem value={"décembre"}>Décembre</MenuItem>
                                                                </Select>
                                                            </Box>
                                                        </Box>
                                                        <Box className='boxModificationDateContent'>
                                                            <Typography className='typoDateStartEnd' variant="body1" color="initial">Date Fin:</Typography>
                                                            <Box className='boxModificationJourMois'>
                                                                <TextField className='inputFormModificationPrice textFieldFormModificationPrice' id="outlined-number" label="Jour" type="number" InputLabelProps={{shrink: true}} defaultValue={season.dates[0].dayEnd} onChange={(e) => handleChangeVariableDate("dayEnd", e.target.value, 0)} InputProps={{ inputProps: { max: 31 } }}/>
                                                                <Select className='inputFormModificationPrice'  defaultValue={season.dates[0].monthEnd} label="Mois *" onChange={(e) => handleChangeVariableDate("monthEnd", e.target.value, 0)}>
                                                                    <MenuItem value={"janvier"}>Janvier</MenuItem>
                                                                    <MenuItem value={"février"}>Février</MenuItem>
                                                                    <MenuItem value={"mars"}>Mars</MenuItem>
                                                                    <MenuItem value={"avril"}>Avril</MenuItem>
                                                                    <MenuItem value={"mai"}>Mai</MenuItem>
                                                                    <MenuItem value={"juin"}>Juin</MenuItem>
                                                                    <MenuItem value={"juillet"}>Juillet</MenuItem>
                                                                    <MenuItem value={"août"}>Août</MenuItem>
                                                                    <MenuItem value={"septembre"}>Septembre</MenuItem>
                                                                    <MenuItem value={"octobre"}>Octobre</MenuItem>
                                                                    <MenuItem value={"novembre"}>Novembre</MenuItem>
                                                                    <MenuItem value={"décembre"}>Décembre</MenuItem>
                                                                </Select>
                                                            </Box>
                                                        </Box>

                                                        <Box className='boxModificationDateContent'>
                                                            <Typography className='typoDateStartEnd' variant="body1" color="initial">Date début:</Typography>
                                                            <Box className='boxModificationJourMois'>
                                                                <TextField className='inputFormModificationPrice textFieldFormModificationPrice' id="outlined-number" label="Jour" type="number" InputLabelProps={{shrink: true}} defaultValue={season.dates[1].dayStart} onChange={(e) => handleChangeVariableDate("dayStart", e.target.value, 1)} InputProps={{ inputProps: { max: 31 } }}/>
                                                                <Select className='inputFormModificationPrice' defaultValue={season.dates[1].monthStart} label="Mois *" onChange={(e) => handleChangeVariableDate("monthStart", e.target.value, 1)}>
                                                                    <MenuItem value={"janvier"}>Janvier</MenuItem>
                                                                    <MenuItem value={"février"}>Février</MenuItem>
                                                                    <MenuItem value={"mars"}>Mars</MenuItem>
                                                                    <MenuItem value={"avril"}>Avril</MenuItem>
                                                                    <MenuItem value={"mai"}>Mai</MenuItem>
                                                                    <MenuItem value={"juin"}>Juin</MenuItem>
                                                                    <MenuItem value={"juillet"}>Juillet</MenuItem>
                                                                    <MenuItem value={"août"}>Août</MenuItem>
                                                                    <MenuItem value={"septembre"}>Septembre</MenuItem>
                                                                    <MenuItem value={"octobre"}>Octobre</MenuItem>
                                                                    <MenuItem value={"novembre"}>Novembre</MenuItem>
                                                                    <MenuItem value={"décembre"}>Décembre</MenuItem>
                                                                </Select>
                                                            </Box>
                                                        </Box>
                                                        <Box className='boxModificationDateContent'>
                                                            <Typography className='typoDateStartEnd' variant="body1" color="initial">Date Fin:</Typography>
                                                            <Box className='boxModificationJourMois'>
                                                                <TextField className='inputFormModificationPrice textFieldFormModificationPrice' id="outlined-number" label="Jour" type="number" InputLabelProps={{shrink: true}} defaultValue={season.dates[1].dayEnd} onChange={(e) => handleChangeVariableDate("dayEnd", e.target.value, 1)} InputProps={{ inputProps: { max: 31 } }}/>
                                                                <Select className='inputFormModificationPrice'  defaultValue={season.dates[1].monthEnd} label="Mois *" onChange={(e) => handleChangeVariableDate("monthEnd", e.target.value, 1)}>
                                                                    <MenuItem value={"janvier"}>Janvier</MenuItem>
                                                                    <MenuItem value={"février"}>Février</MenuItem>
                                                                    <MenuItem value={"mars"}>Mars</MenuItem>
                                                                    <MenuItem value={"avril"}>Avril</MenuItem>
                                                                    <MenuItem value={"mai"}>Mai</MenuItem>
                                                                    <MenuItem value={"juin"}>Juin</MenuItem>
                                                                    <MenuItem value={"juillet"}>Juillet</MenuItem>
                                                                    <MenuItem value={"août"}>Août</MenuItem>
                                                                    <MenuItem value={"septembre"}>Septembre</MenuItem>
                                                                    <MenuItem value={"octobre"}>Octobre</MenuItem>
                                                                    <MenuItem value={"novembre"}>Novembre</MenuItem>
                                                                    <MenuItem value={"décembre"}>Décembre</MenuItem>
                                                                </Select>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )}
                                            <Box className='boxPriceModificationPrice'>
                                                <Typography className='typoDateStartEnd' variant="body1" color="initial">Prix:</Typography>
                                                <TextField className='inputFormAdminPrice' label="Prix" defaultValue={season.price} type="number" onChange={(e) => setSeasonData({...seasonData, price: e.target.value})} />                                    
                                            </Box>
                                        </Box>
                                        <Box className="centerButtonAdmin">
                                            <Button variant="contained" color="primary" onClick={() => handleValidationModification(season._id)}>Valider</Button>
                                        </Box>
                                    </Box>
                                    <Divider className='dividerAdmin'/>
                                </ListItem>
                            ))}
                        </List>
                        )}
                    </Box>
                    <Box id="boxHoliday" ref={refHolidays}>
                        <Typography className="typoH2AdminPanel" variant="h2" color="#FBAF00">Vacances</Typography>
                        
                        {formHoliday ? (
                            <Box className='boxFormHoliday'>
                                <CloseIcon className='iconAdminPrice iconCloseModification' onClick={() => { setHolidayFormData({ idHoliday: "", year: new Date().getFullYear(), dayStart: 0, dayEnd: 0, monthStart: 0, monthEnd: 0 }); setFormHoliday(false); }}/>
                                <Typography className='typoTitleFormHoliday' variant="h3" color="white">Création</Typography>
                                <Select className='inputSelectHoliday' label="Vacances *" defaultValue={holidaysData[0]._id} onChange={(e) => setHolidayFormData({...holidayFormData, idHoliday: e.target.value})}>
                                {holidaysData.map((holiday) => (
                                    <MenuItem key={holiday._id} value={holiday._id}>{holiday.libelle}</MenuItem>
                                ))}
                                </Select>
                                <TextField className='inputYearHoliday' id="outlined-number" label="Année" type="number" defaultValue={new Date().getFullYear()} onChange={(e) => setHolidayFormData({...holidayFormData, year: parseInt(e.target.value)})} InputProps={{ inputProps: { min: new Date().getFullYear() } }}/>
                                <DateRangePicker 
                                    className='inputDateHoliday'
                                    onChange={(range) => { range !== null ? (setHolidayFormData({...holidayFormData, dayStart: range[0].getDate(), monthStart: range[0].getMonth()+1, dayEnd: range[1].getDate(), monthEnd: range[1].getMonth()+1}))
                                                                          : ( setHolidayFormData({...holidayFormData, dayStart: 0, monthStart: 0, dayEnd: 0, monthEnd: 0}) )}} 
                                    showOneCalendar
                                    disabledDate={beforeToday()}
                                    format="dd/MM/yyyy" 
                                    locale={{
                                        february: 'Fev',
                                        sunday: 'Di',
                                        monday: 'Lu',
                                        tuesday: 'Ma',
                                        wednesday: 'Me',
                                        thursday: 'Je',
                                        friday: 'Ve',
                                        saturday: 'Sa',
                                        ok: 'OK',
                                        today: 'Aujourd\'hui',
                                        yesterday: 'Hier',
                                        hours: 'Heures',
                                        minutes: 'Minutes',
                                        seconds: 'Secondes',
                                        last7Days: '7 derniers jours'
                                    }}
                                    />
                                <Button variant="contained" color="primary" onClick={handleSubmitFormHoliday}>
                                  Envoyer
                                </Button>
                            </Box>
                        ) : ( 
                            <Button className='buttonAddForm' variant="outlined" color="primary" onClick={(e) => handleFormAdd("holiday")}>
                            Ajouter
                            </Button>
                        )}
                        {holidaysData.length === 0 ? (
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress sx={{ margin: 'auto' }}/>
                            </Box>
                        ) : (
                            holidaysData.map((holiday) => (
                                <Box key={holiday._id}>
                                    <Typography className="typoH3AdminPanel" variant="h3" color="#FBAF00">{holiday.libelle}</Typography>
                                    <List>
                                        { holiday.dates.map((date) => (
                                        <ListItem key={date.year} className="listItemHoliday">
                                            <Box className="listItemHolidayContent">
                                                <ListItemText primary={<Typography className='listItemTextBold' variant="body1" color="#FBAF00">{date.year}</Typography>} secondary={<Typography variant="body2" color="white">{`Du ${date.dayStart} ${date.monthStart} au ${date.dayEnd} ${date.monthEnd}`}</Typography>}/>
                                                <ListItemIcon className="boxEditIconAdminHoliday">
                                                    <Delete className='iconAdminHoliday' onClick={() => handleOpenConfirmationDeleteHoliday(holiday._id, date.year)}/>
                                                </ListItemIcon>                                
                                            </Box>
                                            <Divider className='dividerAdmin'/>
                                        </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            ))
                        )}
                    </Box>
                </Box>
            </>
        );
    }
}