import React from 'react';
import { motion, Variants } from "framer-motion"

import Box from '@mui/material/Box';

import imageHost from "../Images/host/host.webp"
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export default function Testimony(props) {
    
    const cardVariant: Variants = {
        offscreen: {
            y: 0
        },
        onscreen: {
            y: -20,
            transition: {
                type: "spring",
                repeat: Infinity,
                repeatType: "reverse",
                repeatDelay: 0.1,
                duration: 1
            }
        }
    };

    return(
        <>
            {/* Section 5: Temoignage */}
            <Box id="sectionTestimony" className="section" sx={{ backgroundColor: "#fff"}}>
                <Typography className="title" variant="h2" color="#3A4F41">Témoignage des hôtes</Typography>
                <Box id='containerTestimony'>
                    <Box id='boxImageHost'>
                        <img id='imageHost' src={imageHost} alt="Photo de l'hôte Véronique" />
                    </Box>
                    <Box className='boxTestimony'>
                        <Typography className='guillemet guillemet1' variant="body1" color="#3A4F41">
                            "
                        </Typography>
                        <Typography variant="body1" color="initial">
                            Il y a 25 ans, j’ai découvert la Martinique qui m’a profondément marqué.
                            La gentillesse des Martiniquais, leur histoire, leur culture et cet environnement exceptionnel de diversité m’ont conquise.
                            Aujourd’hui, j’ai réalisé un rêve, celui de vivre de temps en temps à leur côté, de partager leurs quotidiens.
                            Épouse d’un plongeur passionné, mon mari fut non seulement émerveillé par l’île et ses habitants, mais aussi comblé par le fait de pouvoir se rendre à pied au club de plongée situé à seulement 300 m de l’appartement !
                        </Typography>
                        <Typography className='guillemet guillemet2' variant="body1" color="#3A4F41">
                            "
                        </Typography>
                        <Typography className='typoNameHost' variant="caption" color="#3A4F41">Véronique et Didier</Typography>
                    </Box>
                </Box>
                
                <Typography className="typoInvitation" variant="body1" color="initial">
                    Nous vous invitons à découvrir nos {<span onClick={props.scrollRecommendations} className='typoInvitationSpan'>recommandantions et bons plans</span>} à côté de l'appartement{<span className='noWrap'> :</span>}
                </Typography>

                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    className="containerImages"
                >
                    <Box>
                        <motion.div className="motionImage" variants={cardVariant}>
                            <Box className='boxArrowDownIcon'>
                                <KeyboardArrowDownIcon id="arrowDownIcon" onClick={props.scrollRecommendations}/>
                            </Box>
                        </motion.div>
                    </Box>
                </motion.div>


                <motion.div
                    animate={{ x: 100 }}
                    transition={{ delay: 1 }}
                >
                    
                </motion.div>
            </Box>
        </>
    );
}