import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

import icon001 from "../Images/Equipements/001-hairdryer.webp";
import icon002 from "../Images/Equipements/002-bulles.webp"
import icon003 from "../Images/Equipements/003-goutte-deau.webp"
import icon004 from "../Images/Equipements/004-machine-a-laver.webp"
import icon005 from "../Images/Equipements/005-serviette.webp"
import icon006 from "../Images/Equipements/006-cintre.webp"
import icon007 from "../Images/Equipements/007-stores.webp"
import icon008 from "../Images/Equipements/008-le-fer.webp"
import icon0082 from "../Images/Equipements/008-pince-a-linge.webp"
import icon009 from "../Images/Equipements/009-placard.webp"
import icon010 from "../Images/Equipements/010-wifi.webp"
import icon011 from "../Images/Equipements/011-smart-tv.webp"
import icon012 from "../Images/Equipements/012-climatisation.webp"
import icon013 from "../Images/Equipements/013-ventilateur.webp"
import icon014 from "../Images/Equipements/014-refrigerateur.webp"
import icon015 from "../Images/Equipements/015-congelateur.webp"
import icon016 from "../Images/Equipements/016-four.webp"
import icon017 from "../Images/Equipements/017-four-micro-onde.webp"
import icon018 from "../Images/Equipements/018-lave-vaisselle.webp"
import icon019 from "../Images/Equipements/019-cuisson.webp"
import icon020 from "../Images/Equipements/020-bouilloire.webp"
import icon021 from "../Images/Equipements/021-vaisselle.webp"
import icon022 from "../Images/Equipements/022-cafetiere.webp"
import icon023 from "../Images/Equipements/023-cuisine.webp"
import icon024 from "../Images/Equipements/024-voiture-de-sport.webp"
import icon025 from "../Images/Equipements/025-sol.webp"
import icon026 from "../Images/Equipements/026-porte.webp"
import icon027 from "../Images/Equipements/027-famille.webp"
import icon028 from "../Images/Equipements/028-plage.webp"


export default function ModalEquipements(props) {

  const scroll = "body";

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);

  const salleDeBain = [
    {
      icon: icon001,
      item: "Sèche-cheveux" 
    },
    {
      icon: icon002,
      item: "Produits de nettoyage" 
    },
    {
      icon: icon003,
      item: "Eau chaude" 
    },
  ]

  const chambreLinge = [
    {
      icon: icon004,
      item: "Lave-linge" 
    },
    {
      icon: icon005,
      item: "Serviettes, draps, savon et papier toilette" 
    },
    {
      icon: icon006,
      item: "Cintres" 
    },
    {
      icon: icon007,
      item: "Stores" 
    },
    {
      icon: icon008,
      item: "Fer à repasser" 
    },
    {
      icon: icon0082,
      item: "Étendoir à linge" 
    },
    {
      icon: icon009,
      item: "Espace de rangement pour les vêtements" 
    },
  ]

  const divertissementInternet = [
    {
      icon: icon010,
      item: "Wifi haut débit" 
    },
    {
      icon: icon011,
      item: "Ecran plat haute définition" 
    },
  ]

  const climatisation = [
    {
      icon: icon012,
      item: "Climatisation" 
    },
    {
      icon: icon013,
      item: "Ventilateur de plafond" 
    },
  ]

  const cuisine = [
    {
      icon: icon014,
      item: "Réfrigérateur" 
    },
    {
      icon: icon015,
      item: "Congélateur" 
    },
    {
      icon: icon016,
      item: "Four" 
    },
    {
      icon: icon017,
      item: "Four à micro-ondes" 
    },
    {
      icon: icon018,
      item: "Lave vaisselle" 
    },
    {
      icon: icon019,
      item: "Plaques de cuisson" 
    },
    {
      icon: icon020,
      item: "Bouilloire électrique" 
    },
    {
      icon: icon022,
      item: "Cafetière" 
    },
    {
      icon: icon021,
      item: "Vaisselle et couverts" 
    },
    {
      icon: icon023,
      item: "Équipements de base" 
    },
  ]

  const serviceCaracteristique = [
    {
      icon: icon024,
      item: "Parking gratuit sur place" 
    },
    {
      icon: icon026,
      item: "Entrée privée" 
    },
    {
      icon: icon027,
      item: "Très bon voisinage" 
    },
    {
      icon: icon028,
      item: "Plage accessible à 300 mètres" 
    },
  ]
  
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        id="sectionModalEquipement"
      >
        <DialogTitle id="scroll-dialog-title">Équipements de l'appartement</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          
            <Typography className="titleModalEquipement" variant="h4" color="initial">Salle de Bain</Typography>
            <List>
              {salleDeBain.map((element) => (
                <ListItem key={element.item} className='equipement' disablePadding>
                  <ListItemIcon>
                    <img className='iconEquipement' src={element.icon} alt={element.item} />
                  </ListItemIcon>
                  <ListItemText primary={element.item} />
                </ListItem>
              ))}
            </List>
            <Divider className='dividerModalEquipement'/>

            <Typography className="titleModalEquipement" variant="h4" color="initial">Chambre et Linge</Typography>
            <List>
              {chambreLinge.map((element) => (
                <ListItem key={element.item} className='equipement' disablePadding>
                  <ListItemIcon>
                    <img className='iconEquipement' src={element.icon} alt={element.item} />
                  </ListItemIcon>
                  <ListItemText primary={element.item} />
                </ListItem>
              ))}
            </List>
            <Divider className='dividerModalEquipement'/>

            <Typography className="titleModalEquipement" variant="h4" color="initial">Divertissement et Internet</Typography>
            <List>
              {divertissementInternet.map((element) => (
                <ListItem key={element.item} className='equipement' disablePadding>
                  <ListItemIcon>
                    <img className='iconEquipement' src={element.icon} alt={element.item} />
                  </ListItemIcon>
                  <ListItemText primary={element.item} />
                </ListItem>
              ))}
            </List>
            <Divider className='dividerModalEquipement'/>

            <Typography className="titleModalEquipement" variant="h4" color="initial">Climatisation</Typography>
            <List>
              {climatisation.map((element) => (
                <ListItem key={element.item} className='equipement' disablePadding>
                  <ListItemIcon>
                    <img className='iconEquipement' src={element.icon} alt={element.item} />
                  </ListItemIcon>
                  <ListItemText primary={element.item} />
                </ListItem>
              ))}
            </List>
            <Divider className='dividerModalEquipement'/>

            <Typography className="titleModalEquipement" variant="h4" color="initial">Cuisine et Salle à manger</Typography>
            <List>
              {cuisine.map((element) => (
                <ListItem key={element.item} className='equipement' disablePadding>
                  <ListItemIcon>
                    <img className='iconEquipement' src={element.icon} alt={element.item} />
                  </ListItemIcon>
                  <ListItemText primary={element.item} />
                </ListItem>
              ))}
            </List>
            <Divider className='dividerModalEquipement'/>

            <Typography className="titleModalEquipement" variant="h4" color="initial">Autre</Typography>
            <List>
              {serviceCaracteristique.map((element) => (
                <ListItem key={element.item} className='equipement' disablePadding>
                  <ListItemIcon>
                    <img className='iconEquipement' src={element.icon} alt={element.item} />
                  </ListItemIcon>
                  <ListItemText primary={element.item} />
                </ListItem>
              ))}
            </List>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='grey' onClick={props.handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}