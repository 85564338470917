import React, { useEffect, useRef, useState } from 'react';

import { StyledEngineProvider } from '@mui/material/styles';
import { scrollIntoView } from "seamless-scroll-polyfill";

import Welcome from './Welcome';
import Location from './Location';
import Informations from './Informations';
import Testimony from './Testimony';
import Recommendations from './Recommendations';
import Simulation from './Simulation';
import Footer from './Footer';

// Styles import
import "../Styles/general.css"
import "../Styles/welcome.css"
import "../Styles/location.css"
import "../Styles/informations.css"
import "../Styles/modalImage.css"
import "../Styles/testimony.css"
import "../Styles/recommendations.css"
import "../Styles/simulation.css"
import "../Styles/result.css"
import "../Styles/modalEquipement.css"
import "../Styles/footer.css"

import { getSeasons, getHolidays, getReservations } from '../api/index.js';

export default function HomePage() {

    // Button "Découvrir"
    const refDiscover = useRef(null);
    const scrollDiscover = () => scrollIntoView(refDiscover.current, {behavior : "smooth"});

    // Button "Recommendations"
    const refRecommendations = useRef(null);
    const scrollRecommendations = () => scrollIntoView(refRecommendations.current ,{behavior : "smooth"});

    const [seasonsData, setSeasonsData] = useState([])
    const [holidaysData, setHolidaysData] = useState([])
    const [reservationsData, setReservationsData] = useState([])

    // componentDidMount
    useEffect(() => {
        const fetchSeason = async () => {
            try {
                const seasons = await getSeasons();
                if(seasons && seasons.data) {
                    setSeasonsData(seasons.data)
                }
            } catch (error) {
                console.log(error)
            }
        }

        const fetchHolidays = async () => {
            try {
                const holidays = await getHolidays();
                if(holidays && holidays.data) {
                    setHolidaysData(holidays.data)
                }
            } catch (error) {
                console.log(error)
            }
        }

        const fetchReservations = async () => {
            try {
                const reservations = await getReservations();
                if(reservations && reservations.data) {
                    setReservationsData(reservations.data)
                }
            } catch (error) {
                console.log(error)
            }
        }

        fetchSeason();
        fetchHolidays();
        fetchReservations();
    },[])
    
    return(
        <>
        <StyledEngineProvider injectFirst>
            <Welcome scrollDiscover={scrollDiscover} />
            <Location refDiscover={refDiscover} />
            <Informations />
            <Testimony scrollRecommendations={scrollRecommendations} />
            <Recommendations refRecommendations={refRecommendations} />
            <Simulation seasonsData={seasonsData} holidaysData={holidaysData} reservationsData={reservationsData}/>
            <Footer />
        </StyledEngineProvider>
        </>
    );
}