import React, { useState } from 'react';
import { motion } from "framer-motion"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'

import image1 from "../Images/Apartment/photoAppart1.webp"
import image2 from "../Images/Apartment/photoAppart2.webp"
import image3 from "../Images/Apartment/photoAppart3.webp"
import image4 from "../Images/Apartment/photoAppart4.webp"
import image5 from "../Images/Apartment/photoAppart5.webp"
import image6 from "../Images/Apartment/photoAppart6.webp"
import image7 from "../Images/Apartment/photoAppart7.webp"
import image8 from "../Images/Apartment/photoAppart8.webp"
import image9 from "../Images/Apartment/photoAppart9.webp"
import image10 from "../Images/Apartment/photoAppart10.webp"
import image11 from "../Images/Apartment/photoAppart11.webp"
import image12 from "../Images/Apartment/photoAppart12.webp"
import image13 from "../Images/Apartment/photoAppart13.webp"
import image14 from "../Images/Apartment/photoAppart14.webp"
import image15 from "../Images/Apartment/photoAppart15.webp"
import image16 from "../Images/Apartment/photoAppart16.webp"

import palmTree from "../Images/Informations/palm-tree.webp"
import cottage from "../Images/Informations/cottage.webp"
import baggage from "../Images/Informations/baggage.webp"
import Button from '@mui/material/Button'
import ModalEquipements from './ModalEquipement';

export default function Informations() {
    
    let strongPoints = [
        {
            key: 1,
            icon: cottage,
            text: "F3 entièrement rénové de 77m² dont 15m² de terrasse couverte.",
        },
        {
            key: 2,
            icon: palmTree,
            text: "À 300m de la plage !",
        },
        {
            key: 3,
            icon: baggage,
            text: "Une conciergerie sur place s'occupe de tout.",
        },
    ]

    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        0: { items: 1 },
        768: { items: 2 },
        1200: { items: 3 },
    };

    const items = [
        <img className="imageCarousel" src={image1} onDragStart={handleDragStart} alt="Salon de l'appartement alizé" />,
        <img className="imageCarousel" src={image2} onDragStart={handleDragStart} alt="Vue d'ensemble de la cuisine de l'appartement alizé" />,
        <img className="imageCarousel" src={image4} onDragStart={handleDragStart} alt="Vue d'ensemble du salon de l'appartement alizé" />,
        <img className="imageCarousel" src={image15} onDragStart={handleDragStart} alt="Autre vu du salon" />,
        <img className="imageCarousel" src={image16} onDragStart={handleDragStart} alt="Cuisine de l'appartement alizé" />,
        <img className="imageCarousel" src={image5} onDragStart={handleDragStart} alt="Autre vue de la cuisine" />,
        <img className="imageCarousel" src={image6} onDragStart={handleDragStart} alt="Chambre 1 de l'appartement alizé" />,
        <img className="imageCarousel" src={image12} onDragStart={handleDragStart} alt="Autre vue de la chambre 1" />,
        <img className="imageCarousel" src={image7} onDragStart={handleDragStart} alt="Chambre 2 de l'appartement alizé" />,
        <img className="imageCarousel" src={image14} onDragStart={handleDragStart} alt="Autre vue de la chambre 2" />,
        <img className="imageCarousel" src={image13} onDragStart={handleDragStart} alt="Couloir de l'appartement alizé avec une tortue au mur" />,
        <img className="imageCarousel" src={image8} onDragStart={handleDragStart} alt="Salle de bain de l'appartement alizé" />,
        <img className="imageCarousel" src={image11} onDragStart={handleDragStart} alt="Déco Cactus au mur" />,
        <img className="imageCarousel" src={image3} onDragStart={handleDragStart} alt="Terrasse de l'appartement alizé" />,
        <img className="imageCarousel" src={image9} onDragStart={handleDragStart} alt="Vue de l'extérieur de l'appartement alizé" />,
        <img className="imageCarousel" src={image10} onDragStart={handleDragStart} alt="Plage de l'anse à l'Âne" />,
    ];

    // Modal Equipement
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Animation
    const variantsMotion: Variants = {
        offscreen: {
            opacity: 0,
            x: -200
          },
        onscreen: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 0.4,
                duration: 0.6
            }
        },
    };

    // Effect scroll reveal
    const variantsMotion2: Variants = {
        offscreen: {
            opacity: 0
          },
        onscreen: {
            opacity: 1,
            transition: {
                delay: 0.4,
                duration: 0.6
            }
        },
    };

    return(
        <>
            {/* Section 3: Informations */}
            <Box id="sectionInformations" className="section" sx={{ backgroundColor: "#016FB9" }}>
                <ModalEquipements open={open} handleClose={handleClose}/>
                <Typography className="title" variant="h2" color="white">Pourquoi cet appartement{<span className='noWrap'> ?</span>}</Typography>
                <AliceCarousel 
                    mouseTracking 
                    items={items} 
                    responsive={responsive}
                    autoPlay={true}
                    autoPlayInterval="1500"
                    disableButtonsControls={true}
                    disableDotsControls={true}
                    infinite={true}
                    keyboardNavigation={true}
                />
                <Box className="containerStrongPoints">
                        <Box className="boxStrongPoints">
                            {strongPoints.map(element =>
                            <motion.div
                                key={element.key}
                                variants={variantsMotion}
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true}}
                            >
                                <Box className="boxStrongPoint" >
                                    <img className="iconStrongPoint" src={element.icon} alt="icon"></img>
                                    <Typography className="typoStrongPoint" variant="h3" color="white">{element.text}</Typography>
                                </Box>   
                            </motion.div>
                            )}
                        </Box>
                    <Box className="voirPlus2">
                        <motion.div
                            variants={variantsMotion2}
                            initial="offscreen"
                            whileInView="onscreen" 
                            viewport={{ once: true}}  
                        >
                            <Button className='buttonBold' variant="contained" color="primary" onClick={handleOpen}>
                                Voir les équipements
                            </Button>
                        </motion.div>
                    </Box>
                </Box>
            </Box>
        </>
    );
}