import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { addDays, subDays } from "date-fns";import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale'; // for country
import { scrollIntoView } from "seamless-scroll-polyfill";

import { useTheme, IconButton, Button, Alert, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Result from './Result';
import { motion } from "framer-motion"


export default function Simulation(props) {
    
    const theme = useTheme()
    
    // Count adult and child
    const [countAdult, setCountAdult] = useState(0);
    const [maxCount, setMaxCount] = useState(false);
    const [minCountAdult, setMinCountAdult] = useState(true);
    const [minCountChildren, setMinCountChildren] = useState(true);
    const incrementAdult = () => {
        if(countChildren + countAdult < 4) setCountAdult(countAdult+1);
    }
    const decrementAdult = () => {
        if(countAdult > 0) setCountAdult(countAdult-1);
    }

    const [countChildren, setCountChildren] = useState(0);
    const incrementChildren = () => {
        if(countChildren + countAdult < 4) setCountChildren(countChildren+1);
    }
    const decrementChildren = () => {
        if(countChildren > 0) setCountChildren(countChildren-1);
    }

    useEffect(() => {
        if(countChildren !== 0) {
            setMinCountChildren(false)
        } else {
            setMinCountChildren(true)
        }
        if(countAdult !== 0) {
            setMinCountAdult(false)
        } else {
            setMinCountAdult(true)
        }
        if((countChildren + countAdult) === 4) {
            setMaxCount(true);  
        } else {
            setMaxCount(false);
        }
    }, [countAdult, countChildren])    
    

    // handle calendar
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        }
    ]);
    
    const handleOnChangeCalendar = ranges => {
        const { selection } = ranges;
        setSelectionRange([selection]);
    };

    
    // Get all dates of reservation
    const getAlreadyBookDates = () => {
        let alreadyBookDates = []
        
        props.reservationsData.map((reservation) => {
            let startDate = new Date(reservation.startDate);
            let endDate = new Date(reservation.endDate);
            for(let currentDate=startDate ; currentDate <= endDate ; currentDate.setDate(currentDate.getDate()+1)){
                alreadyBookDates.push(new Date(currentDate));
            }
        })
        
        return alreadyBookDates;
    }
    
    const alreadyBookDates = getAlreadyBookDates()

    // Handle resultat
    const [globalError, setGlobalError] = useState('');
    
    const handleShowError = (msg) => {
        setGlobalError(msg)
        setTimeout(() => {
            setGlobalError('');
         }, 4000)
        }
        
    const [dataResult, setDataResult] = useState({})

    const handleSimulation = () => {
        if(countAdult >= 1) {
            if(countChildren >=0) {
                if(countAdult+countChildren <=4) {
                    const dayDifference = Math.floor((selectionRange[0].endDate - selectionRange[0].startDate) / (1000 * 60 * 60 * 24));
                    if(dayDifference >= 6) {
                        const data = { 
                            countAdult: countAdult, 
                            countChildren: countChildren, 
                            startDate: selectionRange[0].startDate,
                            endDate: selectionRange[0].endDate,
                            dayDifference: dayDifference,
                        };
                        setDataResult(data);
                        setGlobalError('')
                    } else {
                        handleShowError("Vous devez choisir 6 jours minimum.")
                    }
                } else {
                    handleShowError("4 personnes maximum.")
                }
            } else {
                handleShowError("Erreur")
            }
        } else {
            handleShowError("Il faut au moins un adulte.")
        }
    }
    
    // Calculation of price
    const indexMonth = {
        0: "janvier",
        1: "février",
        2: "mars",
        3: "avril",
        4: "mai",
        5: "juin",
        6: "juillet",
        7: "août",
        8: "septembre",
        9: "octobre",
        10: "novembre",
        11: "décembre",
    }

    function getKeyByValue(object, value) {
        return parseInt(Object.keys(object).find(key => object[key] === value.toLowerCase()));
    }

    // Handle display result

    // Display result of reservation
    const refResult = useRef(null);

    const [changeDisplay, setChangeDisplay] = useState(0);
        
    const useIsMount = () => {
        const isMountRef = useRef(true);
        useEffect(() => {
            isMountRef.current = false;
        }, []);
        return isMountRef.current;
    };
    
    const isMont = useIsMount();
    
    useEffect(() => {
        if(!isMont) {
            handleCalculationPriceFinal()
        }
    }, [dataResult])

    const [finalPrice, setFinalPrice] = useState(0);
    const [allDatesWithPrice, setAllDatesWithPrice] = useState([]);

    useEffect(() => {
        if(!isMont) {
            setChangeDisplay((curr) => curr+1);
        }
    }, [finalPrice])

    // componentDidUpdate
    useEffect(() => {
        if(refResult.current) {
            scrollIntoView(refResult.current, {behavior : "smooth"})
        }
    }, [changeDisplay])


    const handleCalculationPriceFinal = () => {
        let allDatesWithPriceByDay = handleCalculationPriceByDay();
        let priceOfBook = 0
        allDatesWithPriceByDay.map((date) => {
            priceOfBook += date.price;
        })
        setAllDatesWithPrice(allDatesWithPriceByDay)
        setFinalPrice(priceOfBook);
    }

    const handleCalculationPriceByDay = () => {
        const allReservationDates = getAllReservationDates()
        let allDatesWithPrice = [];
        allReservationDates.map((reservationDate) => {
            const { priceDate, libelleSeason } = getPriceByDate(reservationDate)
            allDatesWithPrice.push({date: reservationDate, price: priceDate, libelleSeason: libelleSeason})
        })

        return allDatesWithPrice
    }

    // Get all dates of reservation
    const getAllReservationDates = () => {
        let allDates = []
        // Do not count the last day
        const endDate = dataResult.endDate-1
        for(let currentDate = new Date(dataResult.startDate); currentDate <= endDate ; currentDate.setDate(currentDate.getDate()+1)){
            allDates.push(new Date(currentDate));
        }
        return allDates;
    }

    const getPriceByDate = (bookDate) => {
        try {
            const structuredSeason = getAllSeasonStructuralByDateAndYear(bookDate.getFullYear());
            const seasonsCorresponding = []
            structuredSeason.map((season) => {
                if(season.dates !== undefined) {
                    season.dates.map((date) => {
                        if(bookDate >= date.startDate && bookDate <= date.endDate) {
                            // prevent duplicates season
                            if(!seasonsCorresponding.includes(season)) {
                                seasonsCorresponding.push(season)
                            }
                        }
                    })
                }
                if(season.holiday !== undefined) {
                    season.holiday.map((date) => {
                        if(bookDate >= date.startDate && bookDate <= date.endDate) {
                            // prevent duplicates season
                            if(!seasonsCorresponding.includes(season)) {
                                seasonsCorresponding.push(season)
                            }
                        }
                    })
                }
            })

            // The price of the date is the price of the season max price for the day corresponding
            let libelleSeason = ""
            let maxPrice = 0;
            seasonsCorresponding.map((season) => {
                if(season.price > maxPrice) {
                    libelleSeason = season.libelle
                    maxPrice = season.price;
                }
            })
            return  { priceDate: maxPrice, libelleSeason: libelleSeason };
        } catch(err) {
            console.log(err)
            // SETeRROR 
            return 0;
        }
    }

    // Transform dates of seasons (month and day) in (new Date()) depending of the year in parameter
    const getAllSeasonStructuralByDateAndYear = (year) => {
        try {
            let allStructuredSeasons = [];
            props.seasonsData.map((season) => {
                let structuredSeason = { libelle: season.libelle, price: season.price}
                if(season.dates.length !== 0) {
                    structuredSeason = {...structuredSeason, dates: [] }
                    season.dates.map((date) => {
                        structuredSeason.dates.push({startDate: new Date(year, getKeyByValue(indexMonth, date.monthStart), date.dayStart), endDate: new Date(year, getKeyByValue(indexMonth, date.monthEnd), date.dayEnd)})
                    })
                }
                if(season.holiday !== undefined) {
                    structuredSeason = {...structuredSeason, holiday: getStructuredHoliday(season.holiday, year)}
                }
                allStructuredSeasons.push(structuredSeason);
            });
            
            return allStructuredSeasons
        } catch(err) {
            console.log(err)
            return 1
        }
    }

    // Transform holidays of seasons (month and day) in (new Date()) depending of the year in parameter
    const getStructuredHoliday = (idHoliday, year) => {
        try {
            let holiday = props.holidaysData.find((holiday) => { return holiday._id === idHoliday })
            let date = holiday.dates.find((date) => { return date.year === year})
            let dateYearBefore = holiday.dates.find((date) => { return date.year === year-1})
            
            // For Noël : dates start/end are in two different years
            let structuredHoliday
            if(holiday.libelle === "Noël") {
                // If client remove holiday year before
                if(dateYearBefore !== undefined) {
                    structuredHoliday = [{startDate: new Date(year, 0, 1), endDate: new Date(year, 0, dateYearBefore.dayEnd)}, { startDate: new Date(year, getKeyByValue(indexMonth, date.monthStart), date.dayStart), endDate: new Date(year, 11, 31) }]
                } else {
                    structuredHoliday = [{ startDate: new Date(year, getKeyByValue(indexMonth, date.monthStart), date.dayStart), endDate: new Date(year, 11, 31) }]
                }
            } else {
                structuredHoliday = [{startDate: new Date(year, getKeyByValue(indexMonth, date.monthStart), date.dayStart), endDate: new Date(year, getKeyByValue(indexMonth, date.monthEnd), date.dayEnd)}]
            }

            return structuredHoliday;
        } catch(err) {
            console.log(err)
            return 1
        }
    }

    // Up 1200px wide
    let valueXstart = -200;
    if(useMediaQuery(theme.breakpoints.up('xl'))) {
        valueXstart = 200;
    } 

    // Animation
    const variantsMotion: Variants = {
        offscreen: {
            opacity: 0,
            x: valueXstart
          },
        onscreen: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 0.4,
                duration: 0.6
            }
        },
    };

    // Animation
    const variantsMotionBis: Variants = {
        offscreen: {
            opacity: 0,
            x: -200
          },
        onscreen: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 0.4,
                duration: 0.6
            }
        },
    };

    // Effect scroll reveal
    const variantsMotion2: Variants = {
        offscreen: {
            opacity: 0
          },
        onscreen: {
            opacity: 1,
            transition: {
                delay: 0.4,
                duration: 0.6
            }
        },
    };

    const variantsMotion3: Variants = {
        offscreen: {
            opacity: 0,
            y: 100
          },
        onscreen: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6
            }
        },
    };

    return(
        // Section 6: Simulation de réservation
        <>
        <Box id="sectionSimulation" className="section" sx={{ backgroundColor: "#016FB9" }}>
            {globalError ? (
            <Box className="boxAlert">
                <Alert className="alert" severity="error" onClose={() => {setGlobalError('')}}>{globalError}</Alert>
            </Box>
            ) : (
            null
            )}
            <Typography className="title" variant="h2" color="white">Simulation de réservation</Typography>
            <Box className='containerSimulation'>
                <Box className='boxPeopleSimulation'>
                    <motion.div
                        variants={variantsMotionBis}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true}}
                    >
                        <Typography className='typoSubtitleSimulation' variant="h3" color="white">Nombre de personnes</Typography>
                        <Typography className='typoSubtitleSimulation' variant="body1" color="white">(4 personnes maximum)</Typography>
                    </motion.div>
                    <motion.div
                        variants={variantsMotion2}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true}}
                    >
                        <Box className='containerCounter'>
                            <Box className='boxCounter'>
                                <Box>
                                    <Typography className='typoCount1' variant="body1" color="white">Adultes</Typography>
                                    <Typography className='typoCount2' variant="body1" color="white">(13 ans et plus)</Typography>
                                </Box>
                                <IconButton {...(minCountAdult ? {disableRipple: true, className:"disableIcon iconSimulation"} : {className: "iconSimulation"})} aria-label="decrement" onClick={decrementAdult}><RemoveIcon /></IconButton>
                                <Typography className="typoCounter" variant="body1" color="white">{countAdult}</Typography>
                                <IconButton {...(maxCount ? {disableRipple: true, className:"disableIcon iconSimulation"} : {className: "iconSimulation"})} aria-label="increment" onClick={incrementAdult}><AddIcon /></IconButton>
                            </Box>
                            <Box className='boxCounter'>
                                <Box>
                                    <Typography className='typoCount1' variant="body1" color="white">Enfants</Typography>
                                    <Typography className='typoCount2' variant="body1" color="white">(De 0 à 12 ans)</Typography>
                                </Box>
                                <IconButton {...(minCountChildren ? {disableRipple: true, className:"disableIcon iconSimulation"} : {className: "iconSimulation"})} aria-label="decrement" onClick={decrementChildren}><RemoveIcon /></IconButton>
                                <Typography className="typoCounter" variant="body1" color="white">{countChildren}</Typography>
                                <IconButton {...(maxCount ? {disableRipple: true, className:"disableIcon iconSimulation"} : {className: "iconSimulation"})} aria-label="increment" onClick={incrementChildren}><AddIcon /></IconButton>
                            </Box>
                        </Box>
                    </motion.div>
                </Box>

                <Box className='boxDateSimulation'>
                    <motion.div
                        variants={variantsMotion}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true}}
                    >
                        <Typography className='typoSubtitleSimulation' variant="h3" color="white">Choisissez vos dates</Typography>
                        <Typography className='typoSubtitleSimulation' variant="body1" color="white">(6 jours minimum)</Typography>
                    </motion.div>
                    <motion.div
                        variants={variantsMotion2}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true}}
                    >
                        <Box className='boxCalendar'>
                            <DateRange
                                className='calendar'
                                onChange={handleOnChangeCalendar}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                ranges={selectionRange}
                                direction="vertical"
                                minDate={new Date()}
                                rangeColors={[theme.palette.primary.main]}
                                locale={locales["fr"]}
                                disabledDates={alreadyBookDates}
                            />
                        </Box>
                    </motion.div>
                </Box>
            </Box>
            
            <motion.div
                variants={variantsMotion3}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true}}
            >
                <Box id='boxButtonSimulation'>
                    <Button id="buttonSimulation" className='buttonBold' size="large" variant="contained" color="primary" onClick={() => handleSimulation()}>Résultat</Button>
                    <Typography variant="body1" color="white">Aucun engagement !</Typography>
                </Box>
            </motion.div>
        </Box>
        { changeDisplay > 0 ? (
            <Result refResult={refResult} seasonsData={props.seasonsData} finalPrice={finalPrice} allDatesWithPrice={allDatesWithPrice} data={dataResult} />
        ): (
            null
        )}
        </>
    );
}