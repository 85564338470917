import axios from 'axios';

const API = axios.create({ baseURL : 'https://us-central1-appartement-alize.cloudfunctions.net/default' })

// Executé à chaque requête
API.interceptors.request.use((req) => {
    if(localStorage.getItem('token')) {
        req.headers.Authorization = `${JSON.parse(localStorage.getItem('token'))}`
    }
    return req;
});

export const getReservations = () => API.get('/reservations');
export const deleteReservation = (id) => API.delete(`/reservations/${id}`);
export const postReservation = (reservationData) => API.post('/reservations', reservationData);
export const putReservation = (id, reservationData) => API.put(`/reservations/${id}`, reservationData);
export const getHolidays = () => API.get('/holidays');
export const putHoliday = (id, holidayData) => API.put(`/holidays/${id}`, holidayData);
export const getSeasons = () => API.get('/seasons');
export const putSeason = (id, seasonData) => API.put(`/seasons/${id}`, seasonData);
export const sendMail = (reservationData) => API.post('/mail/send', reservationData);
export const login = (signInData) => API.post('/users/login', signInData);