import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import plan from "../Images/Location/planAppartement.webp";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material';


export default function ModalImage(props) {

  const imgRef = useRef(null);

  const[displayLoader, setDisplayLoader] = useState(true)

  return (
    <div>
      <Modal id="modalImage" open={props.open} onClose={props.handleClose}>
        <Box className='boxModalPlan'>
          <Box className='boxCloseIconModal'>
            <CloseIcon id="closeIconModal" onClick={props.handleClose} style={{display: displayLoader ? "none" : "block"}}/>
          </Box>
          <Box sx={{display: 'flex'}} style={{display: displayLoader ? "block" : "none"}}>
            <CircularProgress sx={{margin: 'auto'}}/>
          </Box>
          <Box className='boxModalImg' style={{display: displayLoader ? "none" : "block"}}>
            <img ref={imgRef} className="imgModalPlan" src={plan} alt="plan de l'emplacement de l'appartement" onLoad={() => setDisplayLoader(false)}/>
            <LocationOnIcon className="locationIconModal" id="locationIconModal1" />
            <Typography className="locationTypoModal" id="locationTypoModal1" variant="body1" color="initial" >Club de plongée</Typography>
            <LocationOnIcon className="locationIconModal" id="locationIconModal2" />
            <Typography className="locationTypoModal" id="locationTypoModal2" variant="body1" color="initial" >Épicerie</Typography>
            <LocationOnIcon className="locationIconModal" id="locationIconModal3" />
            <Typography className="locationTypoModal" id="locationTypoModal3"variant="body1" color="initial" >Boulangerie</Typography>
            <LocationOnIcon className="locationIconModal" id="locationIconModal4" />
            <Typography className="locationTypoModal" id="locationTypoModal4"variant="body1" color="initial" >Restaurants</Typography>
            <LocationOnIcon id="locationIconModalAppart" />
            <Typography id="locationTypoModalAppart"variant="h5" color="initial" >Appartement</Typography>
          </Box>
            <Typography className="typoModalDistance" variant="h4" color="#FBAF00" style={{display: displayLoader ? "none" : "block"}}>Distance avec la plage: 300m</Typography>
        </Box>
      </Modal>
    </div>
  );
}