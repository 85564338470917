import React, { useRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { motion, useScroll, useTransform } from 'framer-motion';


export default function Welcome(props) {

    // Animation
    const refAnimation = useRef(null);
    const { scrollYProgress } = useScroll({
        target: refAnimation,
    });

    let scrollTransformYTitle = useTransform(scrollYProgress, [0, 1], [-150, 0]);

    const variantsMotion: Variants = {
        offscreen: {
            opacity: 0,
            x: 200
          },
        onscreen: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 0.4,
                duration: 0.6
            }
        },
    };

    return(
        <>
            {/* Section 1: Welcome */}
            <Box id='banner'>
                <Box id="banner-title">
                    <motion.div
                        style={{
                            y: scrollTransformYTitle,
                        }}
                    >
                        <Typography className="typoTitleWelcome" variant="h1" color="white">
                            Appartement Alizé
                        </Typography>
                    </motion.div>
                </Box>
                <motion.div
                    className='boxMotionSubtitle'
                    variants={variantsMotion}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true}}
                >
                    <Box className='boxSubtitle'>
                        <Typography className='typoSubtitleWelcome' variant="h2" color="white">
                            Vous cherchez où loger en Martinique&nbsp;?
                        </Typography>
                        <Typography className='typoSubtitleWelcome' variant="h2" color="white">
                            En famille ou entre amis venez découvrir notre magnifique F3 aux{<span className='noWrap'> Trois-îlets </span>}en Martinique{<span className='noWrap'> !</span>}
                        </Typography>
                        <Box className="boxButtonWelcome">
                            <Button className="buttonWelcome buttonBold" variant="contained" onClick={props.scrollDiscover}>
                                Découvrir
                            </Button>
                        </Box>

                    </Box>
                </motion.div>
                <Box className='boxCaption' ref={refAnimation}>
                    <Typography className="caption" variant="caption" color="white">La Martinique, île des Caraïbes</Typography>
                </Box>
            </Box>
        </>
    );
}