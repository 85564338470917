const validateName = ({name, setNameError}) => {
    return name && name.length < 3
        ? setNameError('Le nom est trop court')
        : name && name.length > 40
        ? setNameError('Le nom est trop long')
        : setNameError('');
};

const validateFirstname = ({ firstname, setFirstnameError }) => {
    return firstname && firstname.length < 3
        ? setFirstnameError('Le prénom est trop court')
        : firstname && firstname.length > 40
        ? setFirstnameError('Le prénom est trop long')
        : setFirstnameError('');
};

const validatePhone = ({ phone, setPhoneError }) => {
    var phoneRegular = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phone && !phone.match(phoneRegular)
        ? setPhoneError('Numéro de téléphone non valide')
        : setPhoneError('');
};

const validateMail = ({ mail, setMailError }) => {
    const mailRegular = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mail && !mail.match(mailRegular)
        ? setMailError('Adresse mail non valide')
        : setMailError('');
};
  
export { validateName, validateFirstname, validatePhone, validateMail };