import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import HomePage from "./Components/Homepage";
import AdminPage from "./Components/Adminpage"
import Login from './Components/Login';

let theme = createTheme({
  palette: {
    primary: {
      main: '#fbaf00',
    },
    secondary: {
      main: '#bfd7b5',
    },
    background1: {
      main: '#3a4f41',
    },
    background2: {
      main: '#48ACF0',
    },
    black: {
      main: '#000000',
    },
    grey: {
      main: '#7d7d7d',
    }
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 600,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  typography: {
    h1: {
      fontFamily: [
        'Bevan',
        'cursive',
      ].join(','),
    },
    h2: {
      fontFamily: [
        'Bevan',
        'cursive',
      ].join(','),
    },
    h3: {
      fontFamily: [
        'Oxygen',
        'sans-serif',
      ].join(','),
    },
    h4: {
      fontFamily: [
        'Oxygen',
        'sans-serif',
      ].join(','),
    },
    h5: {
      fontFamily: [
        'Oxygen',
        'sans-serif',
      ].join(','),
    },
    h6: {
      fontFamily: [
        'Oxygen',
        'sans-serif',
      ].join(','),
    },
    subtitle1: {
      fontFamily: [
        'Oxygen',
        'sans-serif',
      ].join(','),
    },
    body1: {
      fontFamily: [
        'Oxygen',
        'sans-serif',
      ].join(','),
    },
    caption: {
      fontFamily: 'Fresca'
    },
  },
});

theme = responsiveFontSizes(theme);

const App = () => {

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} width="100%"/>
          <Route path="/login" element={<Login getToken={getToken} />} width="100%"/>
          <Route path="/admin" element={<AdminPage getToken={getToken} />} width="100%"/>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;