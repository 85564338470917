import React, { useEffect, useRef, useState } from 'react';
import { motion, Variants } from 'framer-motion'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'


const variantsMotion: Variants = {
    offscreen: {
        opacity: 0
      },
    onscreen: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.4
        }
    },
    hover: {
        scale: 1.05
    }
};

export default function Recommendations(props) {

    const useIsMount = () => {
        const isMountRef = useRef(true);
        useEffect(() => {
          isMountRef.current = false;
        }, []);
        return isMountRef.current;
    };

    const [newBoxDetail, setNewBoxDetail] = useState('');
    const [currentBoxDetail, setCurrentBoxDetail] = useState('');
    const [boolDetailDisplay, setBoolDetailDisplay] = useState(false);

    const isMont = useIsMount();

    useEffect(() => {
        let newBoxDetailElement;

        // Check if we are not in fisrt render
        if (!isMont) {

            // Check if target is on Typography (child inside box)
            if(newBoxDetail.target.classList.contains("typoRecommendation")) {
                newBoxDetailElement = newBoxDetail.target.parentElement.nextSibling
            } else {
                newBoxDetailElement = newBoxDetail.target.nextSibling;
            }

            // Check if there is already one another box detail display
            if(!boolDetailDisplay) {
                displayDetail(newBoxDetailElement);
            } else {
                
                // Check if the detail ask to display is already display
                if (currentBoxDetail === newBoxDetailElement) {
                    hideDetail(currentBoxDetail);
                } else {
                    hideDetail(currentBoxDetail);
                    displayDetail(newBoxDetailElement);
                }
            }
        }
    }, [newBoxDetail])
    
    // Display box detail pass in parameter
    const displayDetail = (boxDetail) => {
        boxDetail.setAttribute('id','boxDetailDisplay');
        setCurrentBoxDetail(boxDetail);
        setBoolDetailDisplay(true);
    }
    
    // Hide box detail pass in parameter
    const hideDetail = (boxDetail) => {
        boxDetail.removeAttribute('id');
        setCurrentBoxDetail("");
        setBoolDetailDisplay(false);
    }
    
    return(
        <>
            {/* Section 6: Recommandations */}
            <Box ref={props.refRecommendations} id="sectionRecommendations" className="section" sx={{ backgroundColor: "#fff"}}>
                <Box id='containerRecommendations'>
                    <Box className='boxRecommendation1'>
                        <motion.div
                            className='boxRecommendationImage boxRecommendation1-1'
                            variants={variantsMotion}
                            initial="offscreen"
                            whileInView="onscreen"
                            whileHover="hover"
                            onClick={(e) => {setNewBoxDetail(e)}}
                        >
                            <Typography className="typoRecommendation typoRecommendation1" variant="h3" color="white">1</Typography>
                            <Typography className="typoRecommendation typoRecommendation2" variant="h3" color="white">Plongée</Typography>
                        </motion.div>
                        <Box className='boxRecommendationDetail detail1-1'>
                            <Typography variant="body1" color="initial">Le club de Plongée “Corail Club Caraïbes” est situé à 300 m de l’appartement. Vous passerez de super plongées dans une très bonne ambiance !</Typography>
                        </Box>

                        <motion.div
                            className='boxRecommendationImage boxRecommendation1-2'
                            variants={variantsMotion}
                            initial="offscreen"
                            whileInView="onscreen"
                            whileHover="hover"
                            onClick={(e) => {setNewBoxDetail(e)}}
                        >
                            <Typography className="typoRecommendation typoRecommendation1" variant="h3" color="white">3</Typography>
                            <Typography className="typoRecommendation typoRecommendation2" variant="h3" color="white">Anses d'Arlet</Typography>
                        </motion.div>
                        <Box className='boxRecommendationDetail detail1-2'>
                            <Typography variant="body1" color="initial">L’Anse d’Arlet est située à quelques kilomètres de l’Anse à l’Âne. L'Église Saint Henri illumine le bourg et fait face au grand ponton. Sur la place, à l’heure du goûter, glace maison ! La plage très fréquentée avec son terrain de volley-ball convivial, mais surtout des rochers proches de la plage, où vous pourrez faire du snorkeling.</Typography>
                        </Box>

                        <motion.div
                            className='boxRecommendationImage boxRecommendation1-3'
                            variants={variantsMotion}
                            initial="offscreen"
                            whileInView="onscreen"
                            whileHover="hover"
                            onClick={(e) => {setNewBoxDetail(e)}}
                        >
                            <Typography className="typoRecommendation typoRecommendation1" variant="h3" color="white">5</Typography>
                            <Typography className="typoRecommendation typoRecommendation2" variant="h3" color="white">Navette</Typography>
                        </motion.div>
                        <Box className='boxRecommendationDetail detail1-3'>
                            <Typography variant="body1" color="initial">Une navette est disponible au ponton de l’Anse à l’Âne à destination de Fort-de-France.</Typography>
                        </Box>
                        
                        <motion.div
                            className='boxRecommendationImage boxRecommendation1-4'
                            variants={variantsMotion}
                            initial="offscreen"
                            whileInView="onscreen"
                            whileHover="hover"
                            onClick={(e) => {setNewBoxDetail(e)}}
                        >
                            <Typography className="typoRecommendation typoRecommendation1" variant="h3" color="white">7</Typography>
                            <Typography className="typoRecommendation typoRecommendation2" variant="h3" color="white">Centre équestre</Typography>
                        </motion.div>
                        <Box className='boxRecommendationDetail detail1-4'>
                            <Typography variant="body1" color="initial">Pour les passionnés d’équitation, un centre équestre est situé à l’Anse à l’Âne.</Typography>
                        </Box>

                    </Box>
                    <Box className='boxRecommendation2'>

                        <motion.div
                            className='boxRecommendationImage boxRecommendation2-1'
                            variants={variantsMotion}
                            initial="offscreen"
                            whileInView="onscreen"
                            whileHover="hover"
                            onClick={(e) => {setNewBoxDetail(e)}}
                        >
                            <Typography className="typoRecommendation typoRecommendation1" variant="h3" color="white">2</Typography>
                            <Typography className="typoRecommendation typoRecommendation2" variant="h3" color="white">Anse Dufour</Typography>
                        </motion.div>
                        <Box className='boxRecommendationDetail detail2-1'>
                            <Typography variant="body1" color="initial">L’Anse Dufour, Anse Noir et la Grande Anse situé à quelques kilomètres de l’Anse à l’Âne, vous êtes assurés de voir des tortues qui viennent s’alimenter d’herbiers, toujours une rencontre magique. Ne pas les toucher et garder une distance respectable.</Typography>
                        </Box>

                        <motion.div
                            className='boxRecommendationImage boxRecommendation2-2'
                            variants={variantsMotion}
                            initial="offscreen"
                            whileInView="onscreen"
                            whileHover="hover"
                            onClick={(e) => {setNewBoxDetail(e)}}
                        >
                            <Typography className="typoRecommendation typoRecommendation1" variant="h3" color="white">4</Typography>
                            <Typography className="typoRecommendation typoRecommendation2" variant="h3" color="white">Savane des esclaves</Typography>
                        </motion.div>
                        <Box className='boxRecommendationDetail detail2-2'>
                            <Typography variant="body1" color="initial">Un lieu unique aux Trois-îlets où vous comprendrez la Martinique et les Martiniquais !</Typography>
                        </Box>
                        
                        <motion.div
                            className='boxRecommendationImage boxRecommendation2-3'
                            variants={variantsMotion}
                            initial="offscreen"
                            whileInView="onscreen"
                            whileHover="hover"
                            onClick={(e) => {setNewBoxDetail(e)}}
                        >
                            <Typography className="typoRecommendation typoRecommendation1" variant="h3" color="white">6</Typography>
                            <Typography className="typoRecommendation typoRecommendation2" variant="h3" color="white">Village de la poterie</Typography>
                        </motion.div>
                        <Box className='boxRecommendationDetail detail2-3'>
                            <Typography variant="body1" color="initial">Le Village de la poterie est l’endroit où vous trouverez tous vos petits cadeaux et souvenirs.</Typography>
                        </Box>

                        <motion.div
                            className='boxRecommendationImage boxRecommendation2-4'
                            variants={variantsMotion}
                            initial="offscreen"
                            whileInView="onscreen"
                            whileHover="hover"
                            onClick={(e) => {setNewBoxDetail(e)}}
                        >
                            <Typography className="typoRecommendation typoRecommendation1" variant="h3" color="white">8</Typography>
                            <Typography className="typoRecommendation typoRecommendation2" variant="h3" color="white">Location Bateau</Typography>
                        </motion.div>
                        <Box className='boxRecommendationDetail detail2-4'>
                            <Typography variant="body1" color="initial">Mais aussi location de bateau, Visiter la Mangrove... et bien sûr pour les amateurs beaucoup de randonnées !</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}